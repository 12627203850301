@mixin g-bottom-decoration() {
	background: $color-white url('../img/menu-bottom-decoration.svg') -30px 100.1% repeat-x;
	background-size: 253px 3px;

	.b-theme_babies & {
		background-image: url('../img/menu-bottom-decoration-babies.svg');
	}

	.b-theme_hmv & {
		background-image: none;
		border-bottom: 9px solid var(--color-brand);
		border-radius: initial;
	}

	.b-theme_rle & {
		background-image: url('../img/menu-bottom-decoration-rle.svg');
	}
}
