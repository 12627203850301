/*md
# b-button

Buttons express what action will occur when the user clicks or touches it.
Buttons are used to initialize an action, either in the background or foreground of an experience.

This is basic button component. It presents in 5 explicit types.

```html_example
<span class="b-button">Button type 1</span>
<span class="b-button m-type_2">Button type 2</span>
<span class="b-button m-green">Button type 3</span>
<span class="b-button m-red">Button type 4</span>
<span class="b-button m-type_5">Button type 5</span>
```

## Buttons substrates

It could be added to different types of HTML-elements (button, input[submit|button|reset], a, div, span).

```html
<button class="b-button m-green" tabindex="0">Button (on button)</button>
<input class="b-button m-green" type="submit" value="Button (on input submit)" />
<button class="b-button m-green" type="submit">Button (on button submit)</button>
<input class="b-button m-green" type="reset" value="Button (on reset)" />
<a class="b-button m-green" href="#">Button (on a)</a>
<div class="b-button m-green" role="button" tabindex="0">Button (on div)</div>
<span class="b-button m-green" role="button" tabindex="0">Button (on span)</span>
```

```html_example
<div style="overflow: hidden;">

<button class="b-button " tabindex="0">Button (on button)</button>
<input class="b-button " type="submit" value="Button (on input submit)" />
<button class="b-button " type="submit">Button (on button submit)</button>
<input class="b-button " type="reset" value="Button (on reset)" />
<a class="b-button " href="#">Button (on a)</a>
<div class="b-button " role="button" tabindex="0">Button (on div)</div>
<span class="b-button " role="button" tabindex="0">Button (on span)</span>

</div>
```

## Buttons color and states

```html_example
<div style="overflow: hidden;">
<button class="b-button " tabindex="0">Button</button>
<button class="b-button  m-hover" tabindex="0">Button h</button>
<button class="b-button  m-focus" tabindex="0">Button f</button>
<button class="b-button  m-focus m-active" tabindex="0">Button a</button>
<button class="b-button  m-disabled" tabindex="0">Button dis</button>
<br />
<br />
<button class="b-button m-type_2" tabindex="0">Button</button>
<button class="b-button m-type_2 m-hover" tabindex="0">Button h</button>
<button class="b-button m-type_2 m-focus" tabindex="0">Button f</button>
<button class="b-button m-type_2 m-focus m-active" tabindex="0">Button a</button>
<button class="b-button m-type_2 m-disabled" tabindex="0">Button dis</button>
<br />
<br />
<button class="b-button m-green" tabindex="0">Button</button>
<button class="b-button m-green m-hover" tabindex="0">Button h</button>
<button class="b-button m-green m-focus" tabindex="0">Button f</button>
<button class="b-button m-green m-focus m-active" tabindex="0">Button a</button>
<button class="b-button m-green m-disabled" tabindex="0">Button dis</button>
<br />
<br />
<div class="b-button m-red" role="button" tabindex="0">Button</div>
<div class="b-button m-red m-hover" role="button" tabindex="0">Button h</div>
<div class="b-button m-red m-focus" role="button" tabindex="0">Button f</div>
<div class="b-button m-red m-focus m-active" role="button" tabindex="0">Button a</div>
<div class="b-button m-red m-disabled" role="button" tabindex="0">Button dis</div>
<br />
<br />
<div class="b-button m-type_5" role="button" tabindex="0">Button</div>
<div class="b-button m-type_5 m-hover" role="button" tabindex="0">Button h</div>
<div class="b-button m-type_5 m-focus" role="button" tabindex="0">Button f</div>
<div class="b-button m-type_5 m-focus m-active" role="button" tabindex="0">Button a</div>
<div class="b-button m-type_5 m-disabled" role="button" tabindex="0">Button dis</div>
</div>
```
*/

// stylelint-disable selector-class-pattern, no-descending-specificity
.b-button,
.btn {
	@include g-button;

	.b-theme_rle & {
		font-weight: bold;
		letter-spacing: 0.05em;
	}

	&:hover,
	&.m-hover {
		@include g-button(_hover);
	}

	&:active,
	&.m-active {
		@include g-button(_active);
	}

	&[disabled],
	&.m-disabled {
		@include g-button(_disabled);
	}

	&.m-orange {
		@include g-button(_orange);

		.b-theme_babies & {
			@include g-button(_pink);
		}

		.b-theme_hmv & {
			@include g-button(_brand);
		}

		.b-theme_rle & {
			@include g-button(_sand);
		}

		.b-theme_roomsandspaces & {
			@include g-button(_teal);
		}
	}

	&.m-green {
		@include g-button(_green);

		.b-theme_rle & {
			@include g-button(_clover);
		}
	}

	&.m-red {
		@include g-button(_red);

		&.gift-message-cancel {
			font-size: 15px;
		}

		.b-theme_rle & {
			@include g-button(_faded_rose);
		}

		.b-theme_hmv & {
			@include g-button(_brand);
		}
	}

	&.m-white {
		@include g-button(_white);

		&.gift-message-confirmation-btn {
			font-size: 15px;

			@include media(lg-up) {
				margin-right: 10px;
			}

			@include media(md-down) {
				margin-bottom: 10px;
			}
		}
	}

	&.m-yellow {
		@include g-button(_yellow);
	}

	&.m-paypal {
		color: $color-black;
		font-family: $font-sans;
		font-size: 14px;
		text-transform: none;

		.b-theme_rle & {
			font-size: 13px;
			font-weight: 400;
		}

		.b-theme_rle & img {
			@include media(sm) {
				width: 83px;
			}
		}

		img {
			margin: rh(-1 0 0 1);
			vertical-align: middle;
			width: 92px;
		}

		&.m-disabled {
			img {
				opacity: 0.8;
			}
		}
	}

	&.m-hidden {
		@include g-button(_hidden);
	}

	&.m-inline {
		@include g-button(_inline);
	}

	&.m-small {
		font-size: 14px;
		height: 30px;
		line-height: 27px;
	}

	&.m-condenced {
		font-size: 15px;
		padding: 0 10px;

		@include media(sm) {
			font-size: 14px;
		}
	}
}

.b-button_scroll-to-top {
	align-items: center;
	background: $color-web;
	border: 1px solid $color-brand64;
	color: var(--color-brand64);
	cursor: pointer;
	display: none;
	height: 48px;
	inset: auto 5% 15% auto;
	justify-content: center;
	position: fixed;
	width: 48px;
	z-index: 10;

	@include media(sm) {
		bottom: 135px;
	}

	&.show {
		display: flex;
	}

	&:hover {
		@include media(md-up) {
			display: flex;
		}
	}

	> svg {
		transform: rotate(270deg);
	}
}

// stylelint-enable
