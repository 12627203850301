.b-select_registry {
	&-item {
		display: block;

		p + p {
			margin-bottom: rh(1);
		}
	}

	&-item_title {
		display: block;
		font-weight: bold;
		margin-bottom: rh(2);
		position: relative;
		top: -3px;
	}

	&-scroll_view {
		max-height: 440px;
		overflow: auto;
	}

	&-view_all {}// stylelint-disable-line block-no-empty

	&-buttons {
		margin-top: rh(4);

		button {
			margin-bottom: rh(4);
		}
	}

	&-small_print {
		font-size: 14px;
	}
}
