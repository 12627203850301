$transition: ease 0.5s;
$arrow-icon-new: 'data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="21"><path fill="white" fill-rule="evenodd" d="M11.568 11.001l-9.035 8.614a1.525 1.525 0 0 1-2.081 0 1.357 1.357 0 0 1 0-1.984l7.994-7.621L.452 2.388a1.357 1.357 0 0 1 0-1.983 1.523 1.523 0 0 1 2.081 0l9.035 8.613c.287.274.431.632.431.992 0 .358-.144.718-.431.991z"/></svg>';

.embeddedServiceSidebarForm.formContent::-webkit-scrollbar {// stylelint-disable-line
	width: 5px !important;// stylelint-disable-line
}

::-webkit-scrollbar {
	width: 15px !important;// stylelint-disable-line
	z-index: 0;
}

::-webkit-scrollbar-track {
	background-color: rgba(#d4d4d4, 1);
	border-radius: 10px;
	z-index: 0;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(#54575a, 1);
	border-radius: 10px;
	z-index: 0;
}

.desktop-bar {
	display: none;
}

.new-layout {
	.n-main_menu-link {
		color: $color-white;
		padding-left: 0;
	}

	&.m-trigger-menu {
		display: none;
		visibility: hidden;
	}
}

.n-main_menu {
	inset: 0;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;

	@include media (lg-up) {
		display: block;
	}

	&-content {
		@include media(md-up) {
			padding: 0 35px;
		}

		@include media(sm) {
			padding: 0 20px;
		}
	}

	&::-webkit-scrollbar {
		width: 8px !important;// stylelint-disable-line
	}

	&-top {
		background-color: $color-brand;// stylelint-disable-line
		display: flex;// stylelint-disable-line
		justify-content: space-between; // stylelint-disable-line

		@include media(sm) {
			margin-top: 10px;
			padding: 0 20px;
		}

		@include media(md-up) {
			margin-top: 10px;
			padding: 0 35px;
		}

		@include media(lg-up) {
			.n-main_menu-close {
				pointer-events: none;// stylelint-disable-line

				svg {
					display: none;
					visibility: hidden;
				}
			}
		}

		button {
			@include g-hamburger-button;

			padding: 0;
			animation: fade-in $motion-ease;
			appearance: none;
			background: none;
			border: none;
			color: $color-white;
			cursor: pointer;
			font-family: inherit;
			font-size: inherit;
			line-height: 16px;

			@include media(sm) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		svg {
			display: inline-block;
		}
	}

	&-close {
		margin-left: auto;
		padding-right: 0;

		svg {
			vertical-align: text-top;
		}
	}

	&-back {
		display: none;
		font-weight: bold;
		text-transform: uppercase;

		svg {
			margin-right: rh(1);
			vertical-align: sub;
		}

		&.m-activated {
			display: block;
			padding-left: 0;
		}

		.n-theme_rle & {
			font-weight: 500;
		}
	}

	&-country_selector {
		@include g-hamburger-button;

		align-items: center;
		border-top: 1px solid $color-divider;
		display: flex;
		line-height: 1;
		padding: 20px 28px;

		.b-theme_hmv & {
			border-top: 0;
		}
	}

	&-footer {
		svg {
			max-height: 45px;
			max-width: 180px;
		}

		.n-main_menu-item {
			&.country-selector {
				padding: 10px 20px 0;

				@include media(md-up) {
					padding: 10px 35px 0;
				}
			}
		}
	}

	&-link { // stylelint-disable-line
		@include g-hamburger-button; // stylelint-disable-line

		color: $color-text;
		display: block;
		font-weight: bold;
		padding: 12px 8px 12px; // stylelint-disable-line
		min-height: auto;
		text-align: left;
		text-decoration: none;

		&.m-navigation {
			color: $color-brand;
			text-transform: capitalize;
			transition: transform $transition;
			cursor: pointer;

			&.new-layout {
				color: $color-white;
				line-height: 18px;
				min-height: 35px;
				padding-left: 0;
				padding-right: 0;
			}
		}

		&.m-account {
			path {
				fill: $color-brand;
			}
		}

		&.m-navigation_logo {
			align-items: center;
			display: flex;
			font-weight: normal;
			width: 100%;
			padding: 10px 0;

			.content-asset {
				width: 100%;
			}

			svg {
				display: block;
				margin: 0 auto;
			}

			path {
				fill: $color-white;
			}
		}

		&.m-navigation_toysrus {
			background-color: $color-blue;
			color: $color-white;
			padding: 15px;
			min-height: 75px;

			.b-theme_toys & {
				display: none;
			}
		}

		&.m-navigation_babies,
		&.m-navigation_babiesrus {
			background-color: $color-purple;
			color: $color-white;
			padding: 15px;
			min-height: 75px;

			.b-theme_babies & {
				display: none;
			}
		}

		&.m-navigation_rns,
		&.m-navigation_roomsandspaces {
			background-color: $color-rooms;
			color: $color-white;
			padding: 15px;
			min-height: 75px;
		}

		&.m-navigation_rle {
			background-color: $color-grey;
			color: $color-white;
			padding: 15px;
			min-height: 75px;

			path {
				stroke: $color-white;
			}

			.b-theme_rle & {
				display: none;
			}
		}

		&.m-navigation_hmv {
			background-color: $color-hmv-pink;
			color: $color-white;
			padding: 15px;
			min-height: 75px;

			.b-theme_hmv & {
				display: none;
			}
		}

		&:hover {
			text-decoration: none;
		}

		&.m-has-submenu {
			background: url($arrow-icon-new) 96% center no-repeat;
			background-size: 8px;
			padding-right: 34px;
		}

		&.m-user {
			border-top: 0;
		}

		&.m-user-modal {
			border-top: 0;
			color: $color-brand;
			text-transform: uppercase;
		}

		&.m-login {
			border-top: 0;
		}

		.n-theme_rle & {
			font-weight: 500;
		}
	}

	&-user_icon,
	&-user_message {
		vertical-align: middle;
	}

	&-user_message {
		margin-left: rh(2);
	}

	&-flyout_pane {
		display: none !important; // stylelint-disable-line
	}

	&-flyout {
		@include prevent-scroll-chain;

		background-color: $color-white;
		inset: 54px 0 0;
		overflow-y: auto;
		position: absolute;
		transform: translateX(100%);
		transition: transform $transition;

		@include media(lg-up) {
			padding: 0 35px;
		}

		@include media(md) {
			padding: 0 35px;
		}

		@include media(sm) {
			padding: 0 20px;
		}

		&::-webkit-scrollbar {
			width: 8px !important;// stylelint-disable-line
		}

		&.new-layout {
			background-color: $color-brand;
		}

		&.m-active {
			z-index: z(components, main-menu-hamburger, flyout-visible);
		}

		&:focus-within {
			z-index: z(components, main-menu-hamburger, flyout-visible);
		}

		&.m-level_2 {
			top: 0;
		}

		&.m-level_3 {
			top: 0;
		}

		&.m-level_4 {
			top: 0;
		}
	}

	&-flyout_title {
		@include g-hamburger-button;

		background-color: $color-brand52;
		border-bottom: transparent;
		color: $color-white;
		display: block;
		font-weight: bold;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;

		&.new-layout {
			background-color: $color-brand;
			color: $color-brand88;
			cursor: default;
			text-transform: unset;
			font-weight: 800;
			font-size: 20px;
			opacity: 0.8;
			border-top: 1px solid $color-divider;
			border-bottom: 1px solid $color-divider;
			padding: 16px 0;
			margin: 12px 0;
			text-align: left;

			.b-theme_hmv & {
				border-top: 0;
				border-bottom: 0;
				opacity: 1;
				color: $color-white;
			}
		}

		&.link { // stylelint-disable-line
			text-decoration: none;
			cursor: pointer;
		}
	}

	.n-main_menu-column {
		display: block;
		margin-top: -1px;
	}

	.n-main_menu-banner {
		display: none;
	}

	// Toggle animation
	&.m-active_level_1,
	&.m-active_level_2,
	&.m-active_level_3,
	&.m-active_level_4 {
		overflow: hidden;

		.n-main_menu-inner .n-main_menu-link.m-navigation {
			transform: translateX(0%);
		}

		.n-main_menu-inner .n-main_menu-link.m-navigation.category-link {
			transform: translateX(0%);
		}

		.n-main_menu-footer {
			visibility: hidden;
		}
	}

	&.m-active_level_1 {
		.n-main_menu-flyout {
			&.m-level_1 {
				transform: translateX(0);
			}

			&.m-level_2 {
				display: none;
				transform: translateX(100%);
			}
		}
	}

	&.m-active_level_2 {
		.n-main_menu-flyout {
			&.m-level_1 {
				overflow: visible;
				transform: translateX(-100%);
			}

			&:not(.m-active).m-level_2 {
				display: none;
			}

			&.m-level_2.m-active {
				overflow-y: auto;
				overflow-x: hidden;
				transform: translateX(100%);
			}
		}
	}

	&.m-active_level_3 {
		.n-main_menu-flyout {
			&.m-level_1 {
				overflow: visible;
				transform: translateX(0%);
			}

			&.m-level_2 { // stylelint-disable-line
				overflow: visible;
				transform: translateX(0%);
			}

			&:not(.m-active).m-level_3 {
				display: none;
			}

			&.m-level_3.m-active {
				overflow-y: auto;
				overflow-x: hidden;
				transform: translateX(0%);
			}
		}
	}

	&.m-active_level_4 {
		.n-main_menu-flyout {
			&.m-level_1 {
				overflow: visible;
				transform: translateX(0%);
			}

			&.m-level_2 { // stylelint-disable-line
				overflow: visible;
				transform: translateX(0%);
			}

			&.m-level_3 { // stylelint-disable-line
				overflow-y: visible;
				transform: translateX(0%);
			}

			&:not(.m-active).m-level_4 {
				display: none;
			}

			&.m-level_4.m-active {
				overflow-y: auto;
				overflow-x: hidden;
				transform: translateX(0%);
			}
		}
	}

	&.m-active_level_10 {
		overflow: hidden;

		.n-main_menu-flyout {
			overflow: visible;

			&.m-level_1 {
				transform: translateX(100%);
			}

			&.m-level_2 { // stylelint-disable-line
				transform: translateX(100%);
			}

			&.m-level_3 { // stylelint-disable-line
				transform: translateX(100%);
			}

			&.m-level_4 { // stylelint-disable-line
				transform: translateX(100%);
			}

			&.m-level_10 { // stylelint-disable-line
				transform: translateX(0);
			}
		}
	}

	.b-main_menu-country_selector {
		opacity: 0.8;
	}
}

@include media(md-down) {
	.b-desktop_bar {
		display: none;
	}
}
