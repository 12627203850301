// stylelint-disable selector-class-pattern
.b-page.has-dialog {
	overflow: hidden;

	.b-page_inner { /* we need a wrapper to handle scroll / no scroll content width bumping */
		overflow-y: scroll;
	}
}

.modal {
	@include g-dialog-backdrop;

	padding: rh(10 0);

	&.modal-message_restriction {
		z-index: 20;
	}
}

// Actual modal
.modal-dialog {
	@include g-bottom-decoration;

	background-position: -30px 0;
	border-radius: $global-radius;
	box-shadow: $depth-3; /* previous added dialog will have less depth that one on top */
	color: $color-text;
	display: flex;
	flex-direction: column;
	margin: auto;
	opacity: 0;
	padding: rh(4);
	position: relative;
	transform: scale(0.9);
	transition: $motion-ease;
	transition-property: opacity, visibility, transform;
	visibility: hidden;
	width: 600px;
	z-index: z(modal);

	@include media(sm) {
		border-radius: 0;
		min-width: 100%;
		width: auto;
	}

	&.gift-modal {
		width: 700px;
	}

	&.is-open {
		opacity: 1;
		transform: scale(1);
		visibility: visible;
	}

	&.m-small {
		width: 400px;

		@include media(sm) {
			min-width: 100%;
			width: 100%;
		}
	}

	&.add-to-cart-modal {
		@include media(md-up) {
			width: 370px;
		}

		.b-product_details-name {
			-webkit-box-orient: vertical; /* stylelint-disable-line */
			display: -webkit-box; /* stylelint-disable-line */
			font-size: 16px;
			font-weight: 400;
			-webkit-line-clamp: 2; /* stylelint-disable-line */
			margin-bottom: 20px;
			overflow: hidden;
			text-align: left;
			text-overflow: ellipsis;
		}
	}

	.login-modal & {
		padding: 20px 40px 40px;
	}

	.modal.m-quick_view & {
		min-height: 300px;
	}
}

.modal-content {
	position: relative; // hold close button where it needs to be placed after all content
}

// Content
.modal-header {
	color: $color-red;
	font: 24px/1.2 $font-serif;
	padding: rh(7 0 5);
	position: relative;
	text-align: center;
	text-transform: uppercase;

	@include media(md-down) {
		.added-to-cart-modal & {
			text-transform: none;
		}
	}

	.modal-message_restriction,
	.modal-message-coupon-errors & {
		padding: 5px 0;
	}

	.modal.m-quick_view & {
		padding: rh(1 0);
	}

	&.m-black {
		color: $color-black;
	}
}

.modal-header .close,
.modal-close {
	@include g-close-button;

	font-size: 30px;
	margin: rh(-3);
	position: absolute;
	right: 0;
	top: 0;
}

.modal-title {
	color: $color-black;
	margin-bottom: 0;

	.modal-message_restriction & {
		font-weight: 500;
	}

	.b-theme_rle & {
		letter-spacing: 0.03em;
	}
}

.modal-body {
	font-size: 16px;
	padding: rh(3);
	position: relative;

	&.m-animated {
		overflow: hidden;
		transition: height $motion-ease;

		&[aria-busy='true'] {
			height: 300px;
		}
	}
}

.modal-spinner {
	left: 50%;
	margin: -9px -9px 0 0;
	opacity: 0;
	position: absolute;
	stroke: $color-brand;
	top: 180px;
	transition: ease 0.6s;
	transition-property: opacity, visibility;
	visibility: hidden;

	.modal-body[aria-busy='true'] + & {
		opacity: 1;
		visibility: visible;

		path {
			animation: rotate infinite ease 1s;
			transform-origin: 18px 18px;
		}
	}
}

.modal-footer {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	padding: rh(3);

	@include media(sm) {
		display: block;
	}

	.b-button + .b-button {
		margin-left: rh(3);

		@include media(sm) {
			margin: rh(3 0 0);
		}
	}

	.button-wrapper {
		display: flex;
		width: 100%;

		@include media(sm) {
			display: block;
		}
	}

	.btns-wrap {
		display: flex;

		&.m-vertical {
			flex-wrap: wrap;

			.b-button {
				margin: rh(0 0 4 0);
			}
		}
	}
}

// Instore pickup modal mods
.modal.m-in_store_pickup {
	.modal-header {
		padding: 23px 27px 20px 23px;
	}

	.modal-body {
		@include media(sm) {
			padding: rh(0);
		}
	}

	.close {
		right: 12px;
	}
}

// Sign in Popup modal mods
.modal.modal-newsletter {
	.modal-dialog {
		background-color: $color-brand;
		background-image: unset;
		background-position: right;
		background-repeat: no-repeat;
		background-size: cover;
		color: $color-white;
		min-height: 651px;
		padding: 65px 60px;
		width: 650px;

		@include media(md-down) {
			padding: 55px 7%;
		}
	}

	.b-theme_toys & .modal-dialog {
		background-image: url('../../img/tru-signup-popup-bg.jpg');
	}

	.b-theme_babies & .modal-dialog {
		background-image: url('../../img/bru-signup-popup-bg.jpg');
	}

	.b-theme_hmv & .modal-dialog {
		background-image: url('../../img/bru-signup-popup-bg.jpg');
	}

	.b-theme_rle & .modal-dialog {
		background-image: url('../../img/rle-signup-popup-bg.jpg');
	}

	.modal-dialog-close {
		background: transparent;
		border: none;
		color: $color-white;
		position: absolute;
		right: 20px;
		top: 20px;

		&:hover {
			cursor: pointer;
		}
	}

	.modal-header {
		color: $color-white;
		font-family: 'Arial', sans-serif;
		font-size: 65px;
		font-weight: bold;
		letter-spacing: -3px;
		line-height: 80px;
		margin-bottom: 25px;
		padding: 0;
		text-align: left;
		text-transform: none;
		width: 75%;

		@include media(sm) {
			font-size: 45px;
			letter-spacing: 0;
			line-height: 60px;
		}

		svg {
			transform: translateY(15%);
		}
	}

	.modal-body {
		margin-bottom: 20px;
		padding: 0;

		.modal-body-text {
			font-size: 28px;
			letter-spacing: -1px;
			line-height: 38px;
			margin-bottom: 10px;

			@include media(sm) {
				font-size: 23px;
				line-height: 33px;
			}
		}

		.form-group {
			margin: 0;
		}

		.form-group input {
			background: none;
			border: none;
			border-bottom: 2px solid $color-white;
			border-radius: 0;
			box-shadow: none;
			color: $color-white;
			font-size: 20px;
			padding: 0;

			&::placeholder {
				color: $color-white;
			}
		}

		.b-theme_toys & .invalid-feedback,
		.b-theme_babies & .invalid-feedback,
		.b-theme_hmv & .invalid-feedback {
			color: $color-yellow;
		}
	}

	.b-theme_babies & .form-group,
	.b-theme_rle & .form-group,
	.b-theme_hmv & .form-group {
		width: 70%;
	}

	.modal-footer {
		display: block;
		padding: 0;

		.b-button {
			background-color: $color-white;
			border-radius: 3px;
			color: $color-brand;
			font-family: 'Arial', sans-serif;
			margin-bottom: 15px;

			@include media(md-up) {
				width: 50%;
			}
		}

		.modal-footer-text {
			font-size: 14px;
			line-height: 22px;
		}
	}

	.modal-thanks {
		font-family: 'Arial', sans-serif;
		font-size: 65px;
		font-weight: bold;
		letter-spacing: -2px;
		line-height: 80px;
		padding-top: 160px;
		text-align: center;

		@include media(sm) {
			font-size: 45px;
			letter-spacing: 0;
			line-height: 60px;
		}

		p + p {
			margin-top: 30px;
			padding: 0 70px;

			@include media(sm) {
				padding: 0 45px;
			}
		}
	}
}

// Fix for content asset 'covid-lp-03-25-20' that contains css that affects the modal in the "R Community" page
#js-modal-postal {
	z-index: 14;

	.modal-body {
		.row {
			background-color: transparent;
		}
	}
}

/*Shipping Address Suggestion Modal*/
#updatedShippingAddressFields {
	.modal-content {
		padding: 24px;
	}

	.modal-description {
		color: #000;
		font-family: var(--font-sans);
		font-size: 16px;
		padding-top: 12px;
		text-transform: none;
	}
}

.b-address_updated {
	display: flex;
	flex-flow: row wrap;

	&-item {
		flex: 0 0 100%;
		padding: 12px;
		position: relative;
		text-align: center;

		@include media(md-up) {
			flex: 0 0 50%;

			&:first-child {
				&::after {
					background-color: $color-black;
					bottom: 0;
					content: '';
					display: block;
					position: absolute;
					right: 0;
					top: 0;
					width: 1px;
				}
			}
		}
	}

	&-item-title {
		display: inline-block;
		font-size: 16px;
		padding-bottom: 12px;
		text-transform: uppercase;
	}

	&-actions {
		flex: 1 0 auto;
	}

	&-action:not(:last-child) {
		margin-bottom: 12px;
	}
}

#warnRegistryContainer {
	.b-product_details-add-to-registry, .js-registry-warn-add-to-cart, .js-registry-warn-add-to-cart-plp { /* stylelint-disable-line */
		width: 100%;

		@include media(md-down) {
			margin: 15px 0;
		}

		@include media(md-up) {
			width: 50%;
		}
	}
}

// stylelint-enable
