@include media(md-down) {
	.b-hamburger {
		@include g-dialog-backdrop;
	}

	.b-hamburger-panel {
		background-color: $color-white;
		bottom: 0;
		font-size: 14px;
		left: 0;
		line-height: 1.2;
		overflow: hidden;
		position: fixed;
		top: 0;
		transform: translateX(-100%);
		transition: visibility, transform $motion-ease-panels;
		visibility: hidden;
		width: 340px;
		z-index: z(modal);

		@include media(sm) {
			width: 280px;
		}

		&.is-open {
			box-shadow: $depth-3;
			transform: translateX(0);
			visibility: visible;
		}
	}
}
