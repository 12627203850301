///	Site width
///	@example
///		@include site-width;

@mixin site-width {
	margin: rh(0) auto;
	max-width: $max-content-width;
	padding: rh(0 10);

	@include media(md) {
		padding: rh(0 10);
	}

	@include media(sm) {
		padding: rh(0 4);
	}
}
