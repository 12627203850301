.b-product_tile {
	border: 1px solid transparent;
	color: $color-text;
	cursor: default;
	display: block;
	text-decoration: none;
	transition: box-shadow $motion-ease;
	user-select: none;

	@include media(lg-up) {
		&:hover {
			border: 1px solid $color-brand94;
			border-radius: $global-radius;
			box-shadow: $depth-3;
		}
	}

	&-inner {
		overflow: hidden;
		padding: rh(5);
		position: relative;

		@include media(md) {
			padding: rh(5 0);
		}

		@include media(sm) {
			padding: rh(2);
		}
	}

	&-caption {
		padding: 0;
	}

	&-add_to_registry {
		margin-top: 20px;

		.b-button {
			background: 0;
			border: 0;
			color: var(--color-brand);
			font-size: 14px;
			height: inherit;
			line-height: 1;
			padding: 0;
			text-align: center;
			white-space: normal;

			&:hover {
				text-decoration: underline;
			}
		}

		.b-button[disabled] {
			background: 0;
			color: var(--color-brand76);
		}

		.b-cart-section & {
			display: none;
		}
	}

	&-title {
		line-height: 1.2;
		margin: rh(5 0);
		max-height: 40px;
		min-height: 40px;
		text-align: left;
	}

	&-title_link {
		-webkit-box-orient: vertical; /* stylelint-disable-line */
		color: $color-text;
		display: -webkit-box; /* stylelint-disable-line */
		font-size: 16px;
		font-weight: normal;
		-webkit-line-clamp: 2; /* stylelint-disable-line */
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			text-decoration: underline;
		}
	}

	&-promotions {
		color: $color-red;
		font-size: 14px;

		.b-theme_hmv & {
			color: var(--color-brand);
		}

		.b-product_promotions-callout {
			margin: 10px 0;
		}
	}

	&-ratings {
		color: $color-grey65;
		height: 16px;
		margin: rh(3 0);

		.bv_stars_component_container { // stylelint-disable-line
			display: flex !important; // stylelint-disable-line
		}

		.BVInlineRatings { // stylelint-disable-line
			align-items: center;
			display: flex;
		}
	}

	&-price {
		height: 45px;
		margin: rh(2 0);

		.b-price {
			align-items: flex-start;
			flex-flow: row nowrap;
		}

		.b-price-label {
			display: inline-block;
			font-size: 16px;
			margin: rh(0 1 0 0);

			@include media(sm) {
				display: none;
			}

			&.m-secondary {
				display: none;
			}
		}

		.b-price-old,
		.b-price-new,
		.b-price-sales {
			align-items: center;
			flex-flow: row wrap;
			font-size: 16px;
			margin: rh(0);
		}

		.b-price-old {
			margin-right: rh(2);
			order: -1;
		}
	}

	&-badge {
		background-size: cover;
		height: 50px;
		left: 0;
		position: absolute;
		top: 0;
		width: 50px;
		z-index: 1;

		.b-product_carousel-image & {
			height: 75px;
			width: 75px;
		}
	}

	&-availability_msg {}// stylelint-disable-line block-no-empty

	&-btns_modal {
		.b-button:first-child {
			margin-bottom: rh(4);
		}
	}

	&-promo {
		color: var(--color-brand);
		font-size: 14px;
		line-height: 1.29;
		margin-top: rh(3);
	}

	&-add_to_cart_btn {
		line-height: 1;
		margin-top: 25px;
		white-space: normal;
	}

	.b-product_shipping_info {
		padding-top: rh(3);
	}

	.b-product_details-stock_error {
		display: none;
	}

	.b-ellipsis {
		align-self: end;
		font-weight: bold;
	}

	.home-delivery-plp {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		.postal-code-delivery_inner {
			padding-bottom: 0;
		}

		.plp-hidden {
			display: none;
		}
	}
}

.b-add_to_registry_modal {
	margin-right: 10px;
}
