/*md

# b-checkbox

This component represents a control that provides a checkbox.

## Default checkbox - 4 variants in position action elements

```html_example
<label class="b-checkbox">
    <input class="b-checkbox-input" type="checkbox" value="check-1" checked />
    <span class="b-checkbox-label"><span>Text for checkbox</span></span>
</label>
<div class="b-checkbox">
    <input class="b-checkbox-input" type="checkbox" id="check-id-1" value="check-2" />
    <label class="b-checkbox-label" for="check-id-1"><span>Text for checkbox</label>
</div>
<label class="b-checkbox">
    <input class="b-checkbox-input" type="checkbox" value="check-3" checked disabled />
    <span class="b-checkbox-label"><span>Text for checkbox</span>
</label>
<div class="b-checkbox">
    <input class="b-checkbox-input" type="checkbox" id="check-id-2" value="check-4" disabled />
    <label class="b-checkbox-label" for="check-id-2"><span>Text for checkbox</label>
</div>
```

## Button checkbox

```html_example
<button class="b-checkbox" data-href="${category.url}">
	<span title="${category.title}" class="b-checkbox-label m-selected" aria-hidden="true">
		Label example
	</span>
</button>
```

## Checkbox without binding to class

```html_example
<div class="form-group">
	<input type="checkbox" id="rememberMe" name="loginRememberMe" value="true">
	<label for="rememberMe">
		Remember me
	</label>
</div>
```
*/
// stylelint-disable selector-no-qualifying-type
.form-group {
	position: relative;

	input[type='checkbox'] {
		@include g-checkbox;

		position: absolute;

		&:hover {
			@include g-checkbox(_hover);
		}

		&:checked {
			@include g-checkbox(_checked);
		}

		&.is-invalid {
			@include g-checkbox(_invalid);

			&:hover {
				@include g-checkbox(_invalid-hover);
			}
		}

		&[disabled] {
			@include g-checkbox(_disabled);
		}
	}

	input[type='checkbox'] + .b-mixed_label,
	input[type='checkbox'] + label {
		display: inline-block;
		line-height: 24px;
		margin-left: rh(10);
		vertical-align: top;
	}

	input[type='checkbox'] + label + .invalid-feedback {
		margin-left: rh(10);
	}
}
// stylelint-enable
