.b-overlay {
	background: rgba($color-black, 0.3);
	display: none;
	inset: 0;
	position: fixed;
	touch-action: none;
	transition: opacity ease 0.4s;
	-webkit-user-drag: none;
	user-select: none;
	z-index: -1;

	.b-overlay-visible & {
		animation: fade-in ease 0.4s;
		display: block;
		z-index: z(overlay-partial);
	}

	.b-overlay-visible.m-hiding & {
		opacity: 0;
	}
}

.l-header_wrapper {
	.b-overlay-visible & {
		z-index: z(header);
	}
}

.l-header-actions,
.l-header-status {
	@include media(md) {
		position: relative;

		.b-overlay-visible & {
			z-index: z(search-visible-elements);
		}
	}
}

.l-content {
	.b-overlay-visible & {
		z-index: z(content);
	}
}
