@include media(lg-up) {
	.b-main_menu {
		background-color: $color-brand;
		font-size: 14px;
		line-height: 1.2;

		.b-theme_rle & {
			background-color: $color-white;
		}

		&-inner {
			align-items: stretch;
			display: flex;
			justify-content: center;
			list-style: none;
			margin: 0 auto;
			max-width: $max-content-width;
		}

		&-content {
			.l-header-sticky & {
				box-shadow: $depth-3;
			}
		}

		&-footer,
		&-top {
			display: none;
		}

		&-link {
			color: $color-text;
			display: block;
			font-weight: bold;
			text-decoration: none;

			&:hover {
				text-decoration: none;
			}

			&.m-navigation {
				color: $color-white;
				font-size: 15px;
				height: 100%;
				padding: rh(4);
				position: relative;
				text-align: center;
				text-transform: uppercase;

				.b-theme_rle & {
					color: $color-dark_grey;
				}

				&::after {
					background-color: $color-white;
					border-radius: 3px 3px 0 0;
					bottom: 0;
					content: '';
					display: block;
					height: 0;
					left: 0;
					opacity: 0;
					position: absolute;
					right: 0;
					transition: height ease 0.2s;
				}

				&[aria-expanded='true'],
				&.m-hover {
					&::after {
						height: 6px;
						opacity: 0.6;

						.b-theme_rle & {
							background-color: $color-sand;
							opacity: 1;
						}
					}
				}
			}

			&.m-regular {
				border: 1px solid transparent;
				border-radius: $global-radius;
				display: block;
				margin: rh(0 0 1 -5);
				padding: rh(2 5);

				&:hover {
					box-shadow: $depth-1;
					color: $color-link;
				}

				&:active {
					border: 1px solid $color-brand;
					box-shadow: none;
					transition: box-shadow $motion-ease;
				}

				&.m-active {
					border: 1px solid $color-brand;
				}
			}

			.b-theme_rle & {
				font-weight: 500;
			}
		}

		&-flyout_pane {
			@include g-bottom-decoration; // stylelint-disable-line

			background: $color-white;
			border-radius: 0 0 $global-radius $global-radius;
			left: 0;
			position: absolute;
			right: 0;
			transition: $motion-ease;
			transition-property: height, opacity, visibility;
			visibility: hidden;
			z-index: z(components, main-menu, flyout-pane);

			.l-header-sticky & {
				height: inherit !important; // stylelint-disable-line
				top: 100% !important; // stylelint-disable-line
			}
		}

		&-flyout {
			animation: fade-in $motion-ease;
			background: $color-white;
			display: none;
			left: 0;
			max-height: 80vh;
			overflow: hidden;
			position: absolute;
			right: 0;
			transition: height $motion-ease;
			z-index: z(components, main-menu, flyout-content);

			.l-header-sticky & {
				height: inherit !important; // stylelint-disable-line
			}

			&.m-active {
				display: block;
			}

			&.m-level_2 { // Hide level 2 submenus
				display: none;
			}
		}

		&-flyout_inner {
			align-items: flex-start;
			display: flex;
			justify-content: space-between;
			margin: 0 auto;
			max-width: $max-content-width;
			padding: rh(8);

			.m-type_2 & {
				justify-content: flex-start;
			}
		}

		&-flyout_title {
			display: none;
		}

		.b-main_menu-column {
			margin-right: rh(8);
			max-width: 256px;
			min-width: 256px;
			text-align: left;
		}

		&-banner {
			background-color: $color-brand94;
			border-radius: $global-radius;
			max-width: 256px;
			min-height: 320px;
			min-width: 256px;
			overflow: hidden;
			position: relative;

			img {
				height: 100%;
				left: 0;
				object-fit: cover;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}
}

@include media(md-down) {
	$transition: ease 0.5s;
	$arrow-icon: 'data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="21"><path fill="#{url-color-legacy($color-blue)}" fill-rule="evenodd" d="M11.568 11.001l-9.035 8.614a1.525 1.525 0 0 1-2.081 0 1.357 1.357 0 0 1 0-1.984l7.994-7.621L.452 2.388a1.357 1.357 0 0 1 0-1.983 1.523 1.523 0 0 1 2.081 0l9.035 8.613c.287.274.431.632.431.992 0 .358-.144.718-.431.991z"/></svg>';
	$arrow-icon-babies: 'data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="21"><path fill="#{url-color-legacy($color-purple)}" fill-rule="evenodd" d="M11.568 11.001l-9.035 8.614a1.525 1.525 0 0 1-2.081 0 1.357 1.357 0 0 1 0-1.984l7.994-7.621L.452 2.388a1.357 1.357 0 0 1 0-1.983 1.523 1.523 0 0 1 2.081 0l9.035 8.613c.287.274.431.632.431.992 0 .358-.144.718-.431.991z"/></svg>';
	$arrow-icon-hmv: 'data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="21"><path fill="#{url-color($color-hmv-pink)}" fill-rule="evenodd" d="M11.568 11.001l-9.035 8.614a1.525 1.525 0 0 1-2.081 0 1.357 1.357 0 0 1 0-1.984l7.994-7.621L.452 2.388a1.357 1.357 0 0 1 0-1.983 1.523 1.523 0 0 1 2.081 0l9.035 8.613c.287.274.431.632.431.992 0 .358-.144.718-.431.991z"/></svg>';
	$arrow-icon-rle: 'data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="21"><path fill="#{url-color-legacy($color-grey)}" fill-rule="evenodd" d="M11.568 11.001l-9.035 8.614a1.525 1.525 0 0 1-2.081 0 1.357 1.357 0 0 1 0-1.984l7.994-7.621L.452 2.388a1.357 1.357 0 0 1 0-1.983 1.523 1.523 0 0 1 2.081 0l9.035 8.613c.287.274.431.632.431.992 0 .358-.144.718-.431.991z"/></svg>';

	.b-main_menu {
		inset: 0;
		overflow: hidden;
		overflow-y: scroll;
		position: absolute;

		&-top {
			background-color: $color-brand;
			display: flex;
			justify-content: space-between;

			button {
				@include g-hamburger-button;

				animation: fade-in $motion-ease;
				appearance: none;
				background: none;
				border: none;
				color: $color-white;
				cursor: pointer;
				font-family: inherit;
				font-size: inherit;
			}

			svg {
				display: inline-block;
			}
		}

		&-close {
			margin-left: auto;

			svg {
				vertical-align: text-top;
			}
		}

		&-back {
			display: none;
			font-weight: bold;
			margin-top: -6px;
			text-transform: uppercase;

			svg {
				margin-right: rh(1);
				vertical-align: sub;
			}

			&.m-activated {
				display: block;
			}

			.b-theme_rle & {
				font-weight: 500;
			}
		}

		&-country_selector {
			@include g-hamburger-button;

			align-items: center;
			border-top: 1px solid $color-divider;
			display: flex;
			line-height: 1;
		}

		&-footer {
			svg {
				max-height: 45px;
				max-width: 180px;
			}
		}

		&-link {
			@include g-hamburger-button;

			color: $color-text;
			display: block;
			font-weight: bold;
			text-align: left;
			text-decoration: none;

			&.m-navigation {
				border-top: 1px solid $color-divider;
				color: $color-brand;
				text-transform: uppercase;
				transition: transform $transition;
			}

			&.m-navigation_alt {
				background-color: $color-brand97;
				border-top: 1px solid $color-divider;
				font-weight: normal;
			}

			&.m-navigation_alt_2 {
				border-top: 1px solid $color-divider;
				margin-top: -1px;
				text-transform: uppercase;
				vertical-align: middle;

				svg {
					display: inline-block;
					margin-right: rh(2);
					position: relative;
					top: -2px;
					vertical-align: middle;
				}
			}

			&.m-account {
				path {
					fill: $color-brand;
				}
			}

			&.m-navigation_logo {
				align-items: center;
				display: flex;
				font-weight: normal;
				padding-bottom: 0;
				padding-top: 0;

				svg {
					display: block;
				}

				path {
					fill: $color-white;
				}
			}

			&.m-navigation_toysrus {
				background-color: $color-blue;
				color: $color-white;

				.b-theme_toys & {
					display: none;
				}
			}

			&.m-navigation_babies,
			&.m-navigation_babiesrus {
				background-color: $color-purple;
				color: $color-white;

				.b-theme_babies & {
					display: none;
				}
			}

			&.m-navigation_rns,
			&.m-navigation_roomsandspaces {
				background-color: $color-rooms;
				color: $color-white;

				path.d { /* stylelint-disable-line */
					fill: $color-teal;
				}
			}

			&.m-navigation_rle {
				background-color: $color-grey;
				color: $color-white;

				path {
					stroke: $color-white;
				}

				.b-theme_rle & {
					display: none;
				}
			}

			&.m-navigation_hmv {
				background-color: $color-hmv-pink;
				color: $color-white;

				.b-theme_hmv & {
					display: none;
				}
			}

			&:hover {
				text-decoration: none;
			}

			&.m-regular {
				border-top: 1px solid $color-divider;
			}

			&.m-has-submenu {
				background: url($arrow-icon) 92% center no-repeat;
				background-size: 8px;
				padding-right: 34px;

				.b-theme_babies & {
					background-image: url($arrow-icon-babies);
				}

				.b-theme_hmv & {
					background-image: url($arrow-icon-hmv);
				}

				.b-theme_rle & {
					background-image: url($arrow-icon-rle);
				}
			}

			&.m-user {
				border-top: 0;
			}

			&.m-user-modal {
				border-top: 0;
				color: $color-brand;
				text-transform: uppercase;
			}

			&.m-login {
				border-top: 0;
			}

			.b-theme_rle & {
				font-weight: 500;
			}
		}

		&-user_icon,
		&-user_message {
			vertical-align: middle;
		}

		&-user_message {
			margin-left: rh(2);
		}

		&-item {
			&:last-child {
				.b-main_menu-link {
					border-bottom: 1px solid $color-divider;
				}
			}
		}

		&-flyout_pane {
			display: none !important; // stylelint-disable-line
		}

		&-flyout {
			@include prevent-scroll-chain;

			background-color: $color-white;
			inset: 54px 0 0;
			overflow: hidden;
			overflow-y: auto;
			position: absolute;
			transform: translateX(100%);
			transition: transform $transition;

			&.m-active {
				z-index: z(components, main-menu-hamburger, flyout-visible);
			}

			// should be separate declaration otherwise in case of not support
			// CSS parser fall all selectors list
			&:focus-within {
				z-index: z(components, main-menu-hamburger, flyout-visible);
			}

			&.m-level_2 {
				top: 0;
			}
		}

		&-flyout_title {
			@include g-hamburger-button;

			background-color: $color-brand52;
			border-bottom: transparent;
			color: $color-white;
			display: block;
			font-weight: bold;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;

			.b-theme_rle & {
				font-weight: 500;
			}
		}

		.b-main_menu-column {
			display: block;
			margin-top: -1px;
		}

		.b-main_menu-banner {
			display: none;
		}

		// Toggle animation
		&.m-active_level_1,
		&.m-active_level_2 {
			overflow: hidden;

			.b-main_menu-inner .b-main_menu-link.m-navigation {
				transform: translateX(-100%);
			}

			.b-main_menu-footer {
				visibility: hidden;
			}
		}

		&.m-active_level_1 {
			.b-main_menu-flyout {
				&.m-level_1 {
					transform: translateX(0);
				}

				&.m-level_2 {
					display: none;
					transform: translateX(100%);
				}
			}
		}

		&.m-active_level_2 {
			.b-main_menu-flyout {
				&.m-level_1 {
					overflow: visible;
					transform: translateX(-100%);
				}

				&.m-level_2 {
					display: block;
					overflow: visible;
					transform: translateX(100%);
				}
			}
		}

		&.m-active_level_10 {
			overflow: hidden;

			.b-main_menu-flyout {
				overflow: visible;

				&.m-level_1 {
					transform: translateX(100%);
				}

				&.m-level_2 {
					transform: translateX(100%);
				}

				&.m-level_10 {
					transform: translateX(0);
				}
			}
		}
	}
}
