@media print {
	@page {
		margin: rh(2);
		margin-bottom: auto;
		padding: rh(4 0);
		size: a4; // stylelint-disable-line
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
	}

	.h-for_print {
		display: none !important; // stylelint-disable-line
	}
}
