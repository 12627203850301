.b-header_account {
	align-items: baseline;
	color: $color-brand;
	display: flex;
	font-size: 14px;
	font-weight: bold;
	text-decoration: none;
	text-transform: uppercase;
	user-select: none;

	.b-theme_rle & {
		font-weight: 500;
	}

	&-link_wrapper {
		align-items: baseline;
		color: inherit;
		display: flex;

		&:hover {
			text-decoration: none;
		}
	}

	&-link {
		bottom: -2px;
		color: $color-text;
		cursor: pointer;
		max-width: 150px;
		position: relative;
		text-align: right;
		text-decoration: none;

		@include media(md-down) {
			display: none;
		}

		&:hover {
			color: $color-link;
			text-decoration: underline;
		}

		&.m-user {
			color: $color-brand;
			cursor: default;
			text-align: left;

			&:hover {
				color: $color-brand;
				text-decoration: none;
			}
		}
	}

	&-link_initials {
		font-size: 16px;
		font-weight: 600;

		@include media(sm) {
			border: 1px solid $color-brand94;
			border-radius: 50%;
			box-shadow: $depth-1;
			display: block;
		}
	}

	&-link_mobile {
		display: none;

		@include media(md-down) {
			display: block;
		}
	}

	&-link_desktop {
		@include media(md-down) {
			display: none;
		}
	}
}
