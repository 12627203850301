$offset: 187;
$duration: 1.4s;

.b-spinner {
	left: 50%;
	margin: -19px 0 0 -19px;
	opacity: 0;
	position: absolute;
	stroke: $color-brand;
	stroke-linecap: round;
	top: 50%;
	transition: opacity 0.6s ease;
	z-index: z(spinner);

	&-spinner_line {
		stroke-dasharray: $offset;
		stroke-dashoffset: 0;
		transform-origin: center;
	}

	&.m-animated {
		animation: rotate $duration linear infinite;
		opacity: 1;

		.b-spinner-spinner_line {
			animation: dash $duration ease-in-out infinite;
		}
	}
}
