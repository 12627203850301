/*md

# b-select

A Select widget presents a list of options and allows a user to select one or more of them.
A Select that allows a single option to be chosen is a single-select listbox; one that allows multiple options to be selected is a multi-select listbox.

## Default select box

```html_example
<select class="b-select">
    <option class="b-select-option" label="Please select" value="">Please select</option>
    <option class="b-select-option" label="Ms" value="ms">Ms</option>
    <option class="b-select-option" label="Miss" value="miss">Miss</option>
</select>

<br/>
<br/>

<select class="b-select" disabled>
    <option class="b-select-option" label="Please select" value="">Please select</option>
    <option class="b-select-option" label="Ms" value="ms">Ms</option>
    <option class="b-select-option" label="Miss" value="miss">Miss</option>
</select>
```
*/
$select-arrow-toys: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2710%27%20height%3D%276%27%3E%3Cpath%20fill%3D%27#{url-color-legacy($color-blue)}%27%20d%3D%27M0-.002l5%206.001L9.999-.002H0z%27%2F%3E%3C%2Fsvg%3E';
$select-arrow-babies: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2710%27%20height%3D%276%27%3E%3Cpath%20fill%3D%27#{url-color-legacy($color-purple)}%27%20d%3D%27M0-.002l5%206.001L9.999-.002H0z%27%2F%3E%3C%2Fsvg%3E';
$select-arrow-hmv: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%2710%27%20height%3D%276%27%3E%3Cpath%20fill%3D%27#{url-color($color-hmv-pink)}%27%20d%3D%27M0-.002l5%206.001L9.999-.002H0z%27%2F%3E%3C%2Fsvg%3E';

.b-select,
.custom-select {
	appearance: none;
	background: url($select-arrow-toys) no-repeat right 0.75rem center;
	background-color: $color-white;
	background-size: 10px 6px;
	border: 1px solid $color-brand76;
	border-radius: $global-radius;
	box-shadow: none;
	box-sizing: border-box;
	color: $color-text;
	cursor: pointer;
	font-family: $font-sans;
	font-size: 16px;
	font-weight: normal;
	height: 40px;
	line-height: 40px;
	outline: 0;
	padding: rh(0 10 0 4);
	transition: 0.3s ease;
	transition-property: border-color, box-shadow;
	user-select: none;
	width: 100%;

	.b-theme_babies & {
		background-image: url($select-arrow-babies);
	}

	.b-theme_hmv & {
		background-image: url($select-arrow-hmv);
		border-color: var(--color-neutral-700);
	}

	&.is-invalid {
		border-color: $color-red;
	}

	&.m-disabled,
	&[disabled] {
		border-color: $color-disabled;
		color: $color-grey65;
		cursor: default;
		pointer-events: none;

		.b-theme_babies & {
			border-color: var(--color-neutral-700);
		}
	}

	&:focus::-ms-value {
		background: transparent;
		color: $color-text;
	}

	&::-ms-expand {
		display: none;
	}

	&:-moz-focusring {
		color: transparent;
		text-shadow: 0 0 0 $color-text;
	}

	&:hover:not(.m-disabled, [disabled]),
	&:focus:not(.m-disabled, [disabled]) {
		border-color: $color-brand;
		box-shadow: $depth-1;
	}
}

.b-select-newsletter:invalid {
	color: $color-grey65;
}

option {
	font-family: $font-sans;
	font-size: 16px;
}

.b-select-newsletter option:not([disabled]) {
	color: $color-black;
}
