/*md
# Button component

This component is used to style a button

## SCSS structure example:

```scss
.b-button {
	@include g-button;
}
```
*/

@mixin g-button(
	$_: root

) {
	@if $_ == root {
		background-color: $color-brand;
		border: 1px solid transparent;
		border-radius: $global-radius;
		color: $color-white;
		cursor: pointer;
		display: block;
		font-family: $font-serif;
		font-size: 16px;
		height: 40px;
		line-height: 38px;
		outline: none;
		overflow: hidden;
		padding: rh(0 3);
		position: relative;
		text-align: center;
		text-decoration: none;
		text-overflow: ellipsis;
		text-transform: uppercase;
		transition: background-color ease 0.4s;
		user-select: none;
		white-space: nowrap;
		width: 100%;

		.b-theme_hmv & {
			font-weight: 900;
		}
	}

	@if $_ == _hover {
		background-color: $color-brand52;
		text-decoration: none;
	}

	@if $_ == _active {
		outline: none;
		transform: scale(0.97);
	}

	@if $_ == _disabled {
		background-color: $color-brand94;
		box-shadow: none;
		color: $color-brand76;
		cursor: default;
		pointer-events: none;
		transform: none;
	}

	@if $_ == _inline {
		display: inline-block;
		width: auto;
	}

	@if $_ == _brand {
		background-color: var(--color-brand);

		&:hover,
		&.m-hover {
			background-color: var(--color-brand64);

			@include media(md-down) {
				background-color: var(--color-brand);
			}
		}

		&:active {
			@include media(md-down) {
				background-color: var(--color-brand64);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: var(--color-brand76);
			color: $color-white;
		}
	}

	@if $_ == _purple {
		background-color: $color-purple;

		&:hover,
		&.m-hover {
			background-color: lighten($color-purple, 12);

			@include media(md-down) {
				background-color: $color-purple;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: lighten($color-purple, 12);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: lighten($color-purple, 45);
			color: $color-white;
		}
	}

	@if $_ == _orange {
		background-color: $color-orange;

		&:hover,
		&.m-hover {
			background-color: lighten($color-orange, 20);

			@include media(md-down) {
				background-color: $color-orange;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: lighten($color-orange, 20);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: lighten($color-orange, 30);
			color: $color-white;
		}
	}

	@if $_ == _green {
		background-color: $color-green;

		&:hover,
		&.m-hover {
			background-color: lighten($color-green, 20);

			@include media(md-down) {
				background-color: $color-green;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: lighten($color-green, 20);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: lighten($color-green, 35);
			color: $color-white;
		}
	}

	@if $_ == _hmv-green {
		background-color: $color-hmv-purple;

		&:hover,
		&.m-hover {
			background-color: $color-hmv-purple;
			opacity: 0.8;

			@include media(md-down) {
				background-color: $color-hmv-purple;
				opacity: 1;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: $color-hmv-purple;
				opacity: 0.8;
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: $color-hmv-purple;
			opacity: 0.6;
			color: $color-white;
		}
	}

	@if $_ == _red {
		background-color: $color-red;

		&:hover,
		&.m-hover {
			background-color: lighten($color-red, 20);

			@include media(md-down) {
				background-color: $color-red;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: lighten($color-red, 20);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: lighten($color-red, 45);
			color: $color-white;
		}
	}

	@if $_ == _yellow {
		background-color: $color-yellow;

		&:hover,
		&.m-hover {
			background-color: lighten($color-yellow, 20);

			@include media(md-down) {
				background-color: $color-yellow;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: lighten($color-yellow, 20);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: lighten($color-yellow, 20);
			color: $color-white;
		}
	}

	@if $_ == _pink {
		background-color: $color-pink;

		&:hover,
		&.m-hover {
			background-color: lighten($color-pink, 20);

			@include media(md-down) {
				background-color: $color-pink;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: lighten($color-pink, 20);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: lighten($color-pink, 25);
			color: $color-white;
		}
	}

	@if $_ == _teal {
		background-color: #00bfb8;

		&:hover,
		&.m-hover {
			background-color: #99e2df;

			@include media(md-down) {
				background-color: #99e2df;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: #99e2df;
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: #e9e9e9;
			color: #c5c5c5;
		}
	}

	@if $_ == _sand {
		background-color: $color-sand;
		color: $color-dark_grey;
		transition-property: background-color, color;

		&:hover,
		&.m-hover {
			background-color: mix($color1:$color-sand, $color2:$color-white, $weight:80%);
			color: mix($color1:$color-dark_grey, $color2:$color-white, $weight:80%);

			@include media(md-down) {
				background-color: $color-sand;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: mix($color1:$color-sand, $color2:$color-white, $weight:80%);
				color: mix($color1:$color-dark_grey, $color2:$color-white, $weight:80%);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: mix($color1:$color-sand, $color2:$color-white, $weight:30%);
			color: mix($color1:$color-dark_grey, $color2:$color-white, $weight:30%);
		}
	}

	@if $_ == _faded_rose {
		background-color: $color-faded_rose;

		&:hover,
		&.m-hover {
			background-color: mix($color1:$color-faded_rose, $color2:$color-white, $weight:80%);

			@include media(md-down) {
				background-color: $color-faded_rose;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: mix($color1:$color-faded_rose, $color2:$color-white, $weight:80%);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: mix($color1:$color-faded_rose, $color2:$color-white, $weight:30%);
		}
	}

	@if $_ == _clover {
		background-color: $color-clover;

		&:hover,
		&.m-hover {
			background-color: mix($color1:$color-clover, $color2:$color-white, $weight:80%);

			@include media(md-down) {
				background-color: $color-clover;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: mix($color1:$color-clover, $color2:$color-white, $weight:80%);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: mix($color1:$color-clover, $color2:$color-white, $weight:30%);
		}
	}

	@if $_ == _dimmed-blue {
		background-color: $color-blue51;

		&:hover,
		&.m-hover {
			background-color: lighten($color-blue51, 20);

			@include media(md-down) {
				background-color: $color-blue51;
			}
		}

		&:active {
			@include media(md-down) {
				background-color: lighten($color-blue51, 20);
			}
		}

		&[disabled],
		&.m-disabled {
			background-color: lighten($color-blue51, 45);
			color: $color-white;
		}
	}

	@if $_ == _white {
		background-color: $color-white;
		border-color: $color-brand;
		color: $color-brand;
		transition: ease 0.4s;
		transition-property: border-color, box-shadow;

		&:hover,
		&.m-hover {
			background-color: $color-white;
			box-shadow: $depth-1;
			opacity: 1;

			@include media(md-down) {
				box-shadow: none;
			}
		}

		&:active {
			@include media(md-down) {
				box-shadow: $depth-1;
			}
		}

		&[disabled],
		&.m-disabled {
			box-shadow: none;
			opacity: 0.4;
		}
	}

	@if $_ == _solid-black {
		background-color: var(--color-neutral-0);
		color: var(--color-neutral-1000);
		transition: ease 0.4s;
		transition-property: border-color, box-shadow;

		&:hover,
		&.m-hover {
			background-color: var(--color-neutral-0);
			opacity: 1;
		}

		&[disabled],
		&.m-disabled {
			opacity: 0.4;
		}
	}

	@if $_ == _hidden {
		display: none;
	}
}
