.new-layout {
	.n-country_selector {
		background-color: $color-brand;
		border: 1px solid $color-brand;
		border-radius: 0;
		display: flex;
		margin: 0;
		overflow: hidden;
		white-space: nowrap;
		width: 100%;

		.n-footer & {
			background: transparent;
			border: 0;

			@include media(sm) {
				display: none;
			}
		}

		&-locale {
			appearance: none;
			background-color: transparent;
			border: none;
			color: rgba($color-white, 0.4);
			cursor: pointer;
			display: inline-block;
			font-family: $font-sans;
			font-size: 14px;
			font-weight: 900;
			height: 30px;
			line-height: 32px;
			margin-top: 0;
			padding: rh(0 2);
			text-align: center;
			text-decoration: none;
			user-select: none;
			width: 50%;

			.b-theme_hmv & {
				color: $color-white;
			}

			.n-theme_rle & {
				font-weight: 500;
			}

			.n-footer & {
				color: $color-brand;
				opacity: 0.8;
			}

			&:first-child {
				border-right: 1px solid $color-brand;
			}

			&:hover {
				color: $color-white;
				text-decoration: underline;

				.n-footer & {
					color: $color-brand;
				}
			}

			&.m-active_locale {
				background-color: rgba($color-white, 0.4);
				color: rgba($color-white, 0.8);
				cursor: default;

				.n-footer & {
					color: $color-brand;
					opacity: 1;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
