.header-banner {
	color: $color-white;
	display: none;
	font-size: 1rem;
	line-height: 1.5;
	overflow: hidden;
	padding: 0;
	position: relative;
	text-align: center;
	transition: height 0.5s;

	@include media(sm) {
		font-size: 0.7em;
	}

	.b-theme_toys & {
		background-color: $color-red;
	}

	.b-theme_roomsandspaces & {
		background-color: $color-teal;
	}

	.b-theme_babies & {
		background-color: $color-pink;
	}

	.b-theme_hmv & {
		background-color: var(--color-brand);
	}

	.b-theme_rle & {
		background-color: $color-sand;
		color: $color-dark_grey;
		font-family: $font-serif;
		font-weight: bold;
	}

	.content { // stylelint-disable-line
		padding: rh(3 0);

		@include media(sm) {
			padding: rh(2 0 1.5);
		}
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	.close-button {
		position: absolute;
		right: rh(2);
		top: 50%;
		transform: translateY(-50%);
	}

	&.js-show {
		display: block;
		padding: rh(0 5);
		transition: height 0.3s ease;

		.l-header-sticky.js-header-sticky & {
			height: 0 !important; // stylelint-disable-line
		}

		@media print {
			display: none;
		}
	}
}
