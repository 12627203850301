.b-link {
	appearance: none;
	background: transparent;
	border: none;
	color: $color-link;
	cursor: pointer;
	display: inline-block;
	font: inherit;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&_phone {
		color: inherit;
		text-decoration: none;
	}
}
