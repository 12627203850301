.b-sticky-footer {
	background: $color-white;
	border-top: 1px solid $color-brand94;
	bottom: 0;
	display: none;
	left: 0;
	position: fixed;
	right: 0;
	z-index: 2;

	&.cookie-active {
		bottom: 64px;

		@include media(sm) {
			bottom: 120px;
		}
	}

	&.m-visible {
		display: block;
	}

	&.m-closed {
		display: none;
	}

	.b-button.m-orange {
		.b-theme_babies & {
			@include g-button(_orange);
		}
	}

	.b-product_details-add_to_cart {
		line-height: 1;
		margin-right: 40px;
		max-width: 200px;
		white-space: normal;

		&.m-green {
			.b-theme_hmv & {
				@include g-button(_hmv-green);
			}
		}

		&.m-red {
			.b-theme_hmv & {
				@include g-button(_brand);
			}
		}

		@include media(sm) {
			margin: 10px 0 0;
			max-width: 100%;
		}
	}

	.b-product_details-store_pickup {
		@include media(sm) {
			margin-bottom: 10px;
		}
	}

	.b-product_details-name {
		font-size: 16px;

		@include media(lg-up) {
			max-width: 300px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.b-product_details-name_wrapper {
		@include media(md-down) {
			padding: 0 0 20px;
		}
	}

	.b-product_quantity {
		@include media(md-down) {
			display: none;
		}
	}

	.b-product_wishlist-link {
		margin-right: 40px;
		position: static;

		@include media(md-down) {
			display: none;
		}
	}

	&_wrapper {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 1200px;
		padding: 20px 0;
		position: relative;

		@include media(md-down) {
			align-items: center;
			flex-direction: column;
			justify-content: center;
			padding: 30px 20px;
		}
	}

	&_column {
		align-items: center;
		display: flex;

		@include media(sm) {
			flex-wrap: wrap;
			margin: 0 20px;
		}

		@include media(lg-up) {
			width: 55%;
		}
	}

	&_column-first {
		@include media(lg-up) {
			width: 40%;
		}
	}

	&_close-button {
		position: absolute;
		right: 0;
		top: 7px;

		@include media(sm) {
			right: 15px;
		}

		button {
			background: 0;
			border: 0;
			color: $color-brand;
			cursor: pointer;
			font-size: 30px;
		}
	}
}
