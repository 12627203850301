.b-footer_email {
	background-color: $color-brand;

	&-section {
		@include site-width;

		border-bottom: 1px solid transparent;
	}

	&-title {
		color: $color-white;
		font: bold 24px/1.2 $font-serif;
		padding: rh(12 0 5);
		text-align: center;

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}
	}

	&-solid_text {
		@include media(md-up) {
			@include solid-text(solid);
		}

		@include media(sm) {
			@include solid-text(new_line);
		}
	}

	&-input_group {
		display: flex;
		justify-content: space-between;
		margin: 0 auto rh(12);
		width: 62%;

		@include media(md) {
			width: 75%;
		}

		@include media(sm) {
			flex-direction: column;
			width: 100%;
		}

		.b-theme_babies & {
			.b-button {
				@include g-button(_yellow);
			}
		}

		.b-theme_hmv & {
			.b-button {
				@include g-button(_solid-black);
			}
		}
	}

	input[type='email'] { // stylelint-disable-line
		flex-grow: 8;
		margin-right: rh(5);
		padding: rh(0 4);
		width: auto;

		@include media(sm) {
			margin-bottom: rh(5);
			margin-right: 0;
		}

		.b-theme_hmv & {
			box-shadow: none;
		}
	}

	&-cta {
		flex-grow: 1;
		margin: rh(2 0);
		padding-left: rh(3);
		padding-right: rh(3);

		.b-theme_babies & {
			@include g-button(_yellow);
		}
	}

	.b-footer_email-section.h-busy { // stylelint-disable-line
		&::after {
			background: $color-brand;
		}
	}

	&-responce_messages {
		display: none;
		left: 0;
		margin-top: rh(3);
		position: absolute;
		right: 0;
		top: 100%;
		z-index: z(components, footer-email);

		&:not(:empty) {
			animation: fade-in ease 0.4s;
			background-color: lighten($color-red, 50%);
			border-radius: $global-radius;
			color: $color-text;
			display: block;
			font-size: 14px;
			padding: rh(4);
			text-align: center;
		}

		&.m-success {
			background-color: lighten($color-green, 50%);
		}
	}
}
