@include media(lg-up) {
	.n-hamburger {
		&.new-layout {
			@include g-dialog-backdrop;

			&::after {
				height: 100%;
				inset: 240px 0;
			}

			&.is-active {
				display: block;
				position: relative;
			}

			&.scrolled-to-top {
				&::after {
					inset: 300px 0;
				}
			}
		}
	}

	.n-hamburger-panel.new-layout {
		background-color: $color-brand;
		font-size: 14px;
		line-height: 1.2;
		overflow: hidden;
		position: relative;
		transform: translateY(-100%);
		transition: visibility, transform ease 0.5s;
		visibility: hidden;
		width: 360px;
		z-index: 12;

		&.is-open {
			box-shadow: $depth-3;
			transform: translateY(0);
			visibility: visible;
		}
	}
}

@include media(md-down) {
	.n-hamburger {
		@include g-dialog-backdrop;

		width: 330px;

		@include media(sm) {
			width: 280px;
		}

		&.is-active {
			overflow: hidden;
		}
	}

	.n-hamburger-panel {
		background-color: $color-white;
		bottom: 0;
		font-size: 14px;
		left: 0;
		line-height: 1.2;
		overflow: hidden;
		position: fixed;
		top: 0;
		transform: translateX(-100%);
		transition: visibility, transform $motion-ease-panels;
		visibility: hidden;
		width: 330px;
		z-index: z(modal);

		@include media(sm) {
			width: 280px;
		}

		&.is-open {
			box-shadow: $depth-3;
			transform: translateX(0);
			visibility: visible;
		}

		&.new-layout {
			background-color: $color-brand;
			width: 330px;

			@include media(sm) {
				width: 280px;
			}
		}
	}
}
