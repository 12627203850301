.b-suggestions_category {
	margin-bottom: rh(6);

	&-link {
		color: $color-text;
		font-size: 16px;
		font-weight: 900;
		text-decoration: none;

		&:hover {
			color: $color-link;
			text-decoration: underline;
		}
	}

	&-parent_title {
		font-weight: normal;
	}

	&:last-child {
		margin-bottom: rh(0);
	}
}
