///	This mixin is made for text line wrappers that are needed to not be broken apart by new line
///	or or that are needed to go in a separate line themselves
///
///	@param {String} $type - Pass one of the predefined keywords
///	@group styling

@mixin solid-text($type: solid) {
	@if $type == solid {
		display: inline-block;
	}

	@if $type == new_line {
		display: block;
	}
}
