@mixin g-input(
	$_: root

) {
	$input-padding: rh(3);
	$font-size: 16px;
	$height: 40px;

	@if $_ == root {
		appearance: none;
		background: $color-white;
		border: 1px solid;
		border-color: $color-brand94;
		border-radius: $global-radius;
		box-shadow: $depth-1;
		box-sizing: border-box;
		color: $color-text;
		cursor: text;
		font-family: $font-sans;
		font-size: $font-size;
		height: $height; // May cause problems on select[multiple]
		outline: none;
		padding: 0 $input-padding;
		transition: ease 0.4s;
		transition-property: border-color, box-shadow;
		vertical-align: baseline;
		width: 100%;

		.b-theme_hmv & {
			border-color: var(--color-neutral-900);
		}

		&::placeholder {
			color: $color-grey65;
		}
	}

	@if $_ == _hover {
		border-color: $color-brand;
	}

	@if $_ == _focus {
		border-color: $color-brand;
	}

	@if $_ == _invalid {
		border-color: $color-red;
		box-shadow: 0 3px 5px 0 rgba($color-red, 0.3);
	}

	@if $_ == _disabled {
		border-color: $color-brand88;
		color: $color-grey65;
		cursor: default;
	}
}
