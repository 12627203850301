/* stylelint-disable */
.b-locator_store {
	&-form {
		text-align: left;
		width: 100%;
	}

	&-right_column {
		@include media(lg-up) {
			margin-top: 10px;
		}

		@include media(md-down) {
			display: flex;
			flex-direction: column-reverse;
			margin: rh(2 0);
		}
	}

	// Content
	&-name {
		font-weight: bold;
		margin-bottom: rh(4);
		text-transform: uppercase;

		@include media(md-down) {
			margin-bottom: rh(2);
		}
	}

	&-id {
		padding-bottom: rh(1);
	}

	&-address {
		p {
			color: $color-text;
			text-decoration: none;
		}

		a {
			display: inline-block;
			margin-top: rh(2);
		}
	}

	&-distance {
		margin-bottom: rh(1);

		@include media(md-down) {
			margin-top: rh(1);
		}

		svg {
			color: $color-brand76;
		}
	}

	// Actions
	&-actions {
		display: flex;
		margin: rh(2 0 6);

		@include media(md-down) {
			display: block;
		}
	}

	&-link {
		background: none;
		border: none;
		color: $color-link;
		cursor: pointer;
		display: inline-block;
		font-family: inherit;
		font-size: inherit;
		line-height: 1.13;
		position: relative;
		text-align: right;
		text-decoration: underline;
		text-transform: none;
	}

	&-direction {
		@include media(lg-up) {
			margin-right: rh(3);
			padding-right: rh(3);
			position: relative;

			&::after {
				background: $color-divider;
				content: '';
				height: 80%;
				position: absolute;
				right: -1px;
				top: 20%;
				width: 1px;
			}
		}

		@include media(md-down) {
			margin-bottom: rh(2);
		}

		&.m-popup {
			margin-right: 0;
			padding-right: 0;

			&::after {
				display: none;
			}
		}
	}

	&-subtitle {
		color: $color-brand;
		font-size: 14px;
		margin-bottom: 10px;
	}

	&-hours {
		display: inline-block;
		position: relative;
	}

	&-cta {
		margin-top: rh(6);
	}

	&-name-wrapper {
		align-items: flex-start;
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		padding-right: 30px;
		position: relative;
	}

	&-name-inner {
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		white-space: nowrap;
	}

	&-address,
	&-id,
	&-right_column,
	&-select-button {
		display: none;

		&.expanded {
			display: block;
		}

		.b-locator_store-form_info & {
			display: block;
		}
	}

	&-form_info {
		@include media(lg-up) {
			display: flex;
			justify-content: space-between;
		}
	}

	&-select-button {
		line-height: 1;
		margin-top: 20px;
		white-space: normal;
	}

	&-arrow {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(50%, 50%);

		&::before {
			border: 10px solid transparent;
			border-top: 10px solid $color-brand52;
			content: '';
		}

		&.expanded {
			top: 30%;
			transform: translate(50%, -50%);

			&::before {
				border: 10px solid transparent;
				border-bottom: 10px solid $color-brand52;
				content: '';
			}
		}
	}
}
