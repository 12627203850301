/* General styles for the whole site */

/*
	stylelint-disable
	selector-max-universal,
	selector-no-qualifying-type,
	declaration-no-important
*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Solway:wght@700&display=swap');

.b-theme_toys { // It should not be :root since `postcss-custom-properties` expect root for `importFrom`
	--color-brand-raw: 218, 85%, 40%;
	/* stylelint-disable color-function-notation */
	--color-brand: hsl(218deg, 85%, 40%);
	--color-brand52: hsl(218deg, 56%, 52%);
	--color-brand64: hsl(218deg, 56%, 64%);
	--color-brand76: hsl(218deg, 56%, 76%);
	--color-brand88: hsl(219deg, 57%, 88%);
	--color-brand94: hsl(219deg, 55%, 94%);
	--color-brand97: hsl(220deg, 60%, 97%);
	--color-link: hsl(218deg, 85%, 40%);
	--color-web: hsl(218deg, 85%, 40%);
	--depth-1: 0 3px 5px hsla(218deg, 85%, 40%, 30%);
	--depth-2: 0 3px 10px hsla(218deg, 85%, 40%, 35%);
	--depth-3: 0 3px 15px hsla(218deg, 85%, 40%, 35%);
	/* stylelint-enable color-function-notation */
	--font-sans: 'Montserrat';
	--font-serif: 'Solway';
	--font-mono: 'DejaVu Sans Mono';
}

.b-theme_babies { // body.b-theme_babies
	--color-brand-raw: 261, 28%, 42%;
	/* stylelint-disable color-function-notation */
	--color-brand: hsl(261deg, 28%, 42%);
	--color-brand52: hsl(261deg, 20%, 53%);
	--color-brand64: hsl(260deg, 20%, 65%);
	--color-brand76: hsl(263deg, 20%, 76%);
	--color-brand88: hsl(260deg, 20%, 88%);
	--color-brand94: hsl(260deg, 20%, 94%);
	--color-brand97: hsl(260deg, 20%, 97%);
	--color-link: hsl(212deg, 50%, 51%);
	--color-web: hsl(261deg, 28%, 42%);
	--depth-1: 0 3px 5px hsla(261deg, 28%, 42%, 30%);
	--depth-2: 0 3px 10px hsla(261deg, 28%, 42%, 35%);
	--depth-3: 0 3px 15px hsla(261deg, 28%, 42%, 35%);
	/* stylelint-enable color-function-notation */
	--font-sans: 'Montserrat';
	--font-serif: 'Solway';
	--font-mono: 'DejaVu Sans Mono';
}

.b-theme_hmv { // body.b-theme_hmv
	--color-brand-raw: 310, 100%, 10%;
	/* stylelint-disable color-function-notation */
	--color-brand: #DA00B7;
	--color-brand52: hsl(310deg, 100%, 53%);
	--color-brand64: hsl(310deg, 100%, 65%);
	--color-brand76: hsl(310deg, 100%, 76%);
	--color-brand88: hsl(310deg, 100%, 88%);
	--color-brand94: hsl(310deg, 100%, 97%);
	--color-brand97: hsl(310deg, 100%, 97%);
	--color-link: var(--color-brand);
	--color-web: var(--color-brand);
	--color-neutral-0: hsl(0deg, 0%, 0%);
	--color-neutral-400: hsl(0deg, 0%, 44%);
	--color-neutral-500: hsl(0deg, 0%, 50%);
	--color-neutral-700: hsl(0deg, 0%, 77%);
	--color-neutral-900: hsl(0deg, 0%, 97%);
	--color-neutral-1000: hsl(0deg, 0%, 100%);
	--depth-1: 0 3px 5px hsla(0deg, 0%, 47%, 30%);
	--depth-2: 0 3px 10px hsla(0deg, 0%, 47%, 35%);
	--depth-3: 0 3px 15px hsla(0deg, 0%, 47%, 35%);
	/* stylelint-enable color-function-notation */
	--font-sans: 'Montserrat';
	--font-serif: 'Solway';
	--font-mono: 'DejaVu Sans Mono';
}

.b-theme_roomsandspaces { // body.b-theme_roomsandspaces
	/* stylelint-disable color-function-notation */
	--color-brand-raw: #004f44;
	--color-brand: #004f44;
	--color-brand52: #00bfb8;
	--color-brand64: #99e2df;
	--color-brand76: #a4cac6;
	--color-brand88: #bfe9e7;
	--color-brand94: rgba(0, 142, 126, 10%);
	--color-brand97: #d9e8e6;
	--color-link: #004f44;
	--color-web: #035a4f;
	--depth-1: 0 3px 10px rgba(0, 79, 68, 30%);
	--depth-2: 0 3px 10px rgba(0, 79, 68, 35%);
	--depth-3: 0 3px 15px rgba(0, 79, 68, 35%);
	/* stylelint-enable color-function-notation */
	--font-sans: 'Montserrat';
	--font-serif: 'Solway';
	--font-mono: 'DejaVu Sans Mono';
}

.b-theme_rle { // body.b-teme_rle
	/* stylelint-disable color-function-notation */
	--color-brand-raw: 45, 2%, 47%;
	--color-brand: hsl(45deg, 2%, 47%);
	--color-brand52: hsl(45deg, 1%, 60%);
	--color-brand64: hsl(180deg, 1%, 70%);
	--color-brand76: hsl(60deg, 2%, 81%);
	--color-brand88: hsl(0deg, 0%, 87%);
	--color-brand94: hsl(0deg, 0%, 94%);
	--color-brand97: hsl(0deg, 0%, 97%);
	--color-link: hsl(45deg, 2%, 47%);
	--color-web: hsl(45deg, 2%, 47%);
	--depth-1: 0 3px 5px hsla(45deg, 2%, 47%, 30%);
	--depth-2: 0 3px 10px hsla(45deg, 2%, 47%, 35%);
	--depth-3: 0 3px 15px hsla(45deg, 2%, 47%, 35%);
	/* stylelint-enable color-function-notation */
	--font-sans: 'Montserrat';
	--font-serif: 'Solway';
	--font-mono: 'DejaVu Sans Mono';
}

.b-theme_toys,
.b-theme_babies,
.b-theme_rle,
.b-theme_hmv {
	direction: ltr;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-synthesis: none;
	-webkit-text-size-adjust: 100%; // stylelint-disable-line
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

// This is an anchor fix related to sticky header
:target {
	background-clip: padding-box;
	border-top: 170px solid transparent;
	margin-top: -170px;

	@include media(sm) {
		border-top: 73px solid transparent;
		margin-top: -73px;
	}
}

ul,
ol,
p {
	list-style: none;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	margin: 0;
}

figure {
	margin: 0;
}

body {
	background: $color-white !important;
	color: $color-text;
	font: 16px/1.375 $font-sans;
	margin: 0;
	min-width: 320px;
	overflow-y: scroll;
	padding: 0;
}

img {
	height: auto;
	max-width: 100%;
}

svg {
	pointer-events: none;
}

a {
	color: $color-link;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	&[href^='tel'] {
		color: inherit;
		pointer-events: none;
		text-decoration: none;

		@include media(md-down) {
			color: $color-link;
			pointer-events: all;
			text-decoration: underline;
		}
	}
}

b,
strong {
	.b-theme_rle & {
		font-weight: 500;
	}
}

.t-table {
	@include t-table;
}

@include normalize-forms;
@include global-animation;
