@mixin g-hamburger-button() {
	line-height: 22px;
	min-height: 54px;
	padding: 17px rh(7) 12px;
	word-wrap: break-word;

	@include media(sm) {
		padding-left: rh(4);
		padding-right: rh(4);
	}
}
