@mixin t-title() {
	display: block;
	font-family: $font-serif;
	font-weight: 600;
	line-height: 1.2;
}

@mixin t-title-1() {
	font-size: 36px;
}

@mixin t-title-2() {
	font-size: 30px;
}

@mixin t-title-3() {
	font-size: 24px;
	text-transform: uppercase;
}

@mixin t-title-4() {
	font: 800 20px/1.2 $font-sans;
}

@mixin t-title-5() {
	font: 800 16px/1.2 $font-sans;
	text-transform: uppercase;
}

@mixin t-title-6() {
	font: 800 16px/1.2 $font-sans;
}
