.b-suggestions_product {
	margin-bottom: rh(8);

	&-link {
		align-items: flex-start;
		color: $color-text;
		display: flex;
		text-decoration: none;

		&:hover {
			text-decoration: none;
		}
	}

	&-image {
		@include g-product-image;

		border-radius: $global-radius;
		height: 96px;
		margin-right: rh(3);
		max-height: 96px;
		max-width: 96px;
		min-width: 96px;
		padding: 0;
		width: 96px;

		img {
			@include g-product-image(_img);
		}
	}

	&-title {
		font-size: 16px;
		line-height: 1.38;
		margin-bottom: rh(5);
	}

	&-price {
		.b-price {
			font-size: 16px;
		}
	}

	&:hover {
		.b-suggestions_product-title {
			color: $color-link;
			text-decoration: underline;
		}
	}
}
