.b-breadcrumbs {
	@include media(sm) {
		@include g-accordion-styles;

		margin-top: -1px;
		padding-left: 0;
		padding-right: 0;
	}

	.m-no_results & {
		display: none;
	}

	i {
		font-style: normal;
	}

	&-wrapper {
		position: relative;

		@include media(md-up) {
			margin: rh(8 0);

			.m-blog_breadcrumbs & {
				margin: rh(2 0 8);
			}
		}

		@include media(sm) {
			@include g-accordion-styles(_item);

			margin-bottom: rh(6);
			min-height: 48px;
		}
	}

	&-content {
		@include media(md-up) {
			max-width: 70%;
			position: relative;

			&::after {
				background: linear-gradient(to right, rgba(white, 0), rgba(white, 1) 80%); // stylelint-disable-line
				bottom: 0;
				content: '';
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 0;
				width: rh(10);
			}
		}

		@include media(sm) {
			@include g-accordion-styles(_content);

			padding: 0;

			&[aria-hidden='false'] {
				@include g-accordion-styles(_content_expanded);

				padding: rh(2 0);
			}
		}
	}

	&-list {
		// stylelint-disable property-no-unknown, property-no-vendor-prefix
		@include media(md-up) {
			overflow: hidden;
			overflow-behavior: contain;
			-webkit-overflow-scrolling: touch;
			overflow-scrolling: touch;
			-ms-overflow-style: none; /* IE */
			overscroll-behavior-x: contain;
			padding-right: rh(5);
			-ms-scroll-chaining: none;
			scrollbar-width: none; /* FF */
			touch-action: pan-x;
			white-space: nowrap;
		}
		// stylelint-enable
	}

	&-item {
		display: inline-block;

		@include media(sm) {
			display: block;
		}
	}

	&-item:first-child {
		@include media(md-up) {
			.b-breadcrumbs-link {
				margin-left: 0;
			}
		}
	}

	&-link {
		display: inline-block;
		font-size: 14px;
		line-height: 32px;
		user-select: none;

		@include media(md-up) {
			margin-left: rh(1);
		}

		@include media(sm) {
			display: block;
		}

		span {
			border-radius: $global-radius;
			padding: rh(1);
		}

		&:hover {
			text-decoration: none;

			span {
				text-decoration: underline;
			}
		}

		&.m-current {
			color: $color-grey65;
			cursor: default;
			pointer-events: none; // it should be link for ARIA and Schema.org requirements

			&:hover {
				span {
					text-decoration: none;
				}
			}

			.b-theme_rle & {
				color: $color-brand52;
			}
		}
	}

	&-button {
		@include g-accordion-styles(_control);

		font-size: 14px;
		line-height: 48px;
		text-transform: none;

		@include media(md-up) {
			display: none;
		}

		&[aria-expanded='true'] {
			@include g-accordion-styles(_control_expanded);

			height: 48px;
			position: absolute;
			right: 0;
			width: 50px;
			z-index: z(popup-menu);
		}
	}

	&-button_text {
		align-items: stretch;
		color: $color-grey44;
		display: flex;
		line-height: 23px;
		margin-left: rh(2);
		padding: rh(3 6 3 0);
	}

	&-button[aria-expanded='true'] &-button_text,
	&-button[aria-expanded='true'] i {
		display: none;
	}
}
