.b-minicart_icon {
	font-size: 14px;

	@include media(md) {
		margin-left: rh(3);
	}

	&-total {}// stylelint-disable-line block-no-empty

	&-link {}// stylelint-disable-line block-no-empty

	&-icon {}// stylelint-disable-line block-no-empty

	&-quantity {
		background-color: $color-red;
		border-radius: 24px;
		color: $color-white;
		font-weight: bold;
		height: 24px;
		line-height: 24px;
		min-width: 24px;
		padding: 0 rh(1);
		position: absolute;
		right: -9px;
		text-align: center;
		top: -9px;

		.b-theme_babies & {
			background-color: $color-pink;
		}

		.b-theme_hmv & {
			background-color: var(--color-brand);
		}

		.b-theme_rle & {
			background-color: $color-sand;
			color: $color-dark_grey;
		}

		&_hidden {
			display: none;
		}
	}
}
