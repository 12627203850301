.b-logo {
	display: block;
	max-height: 77px;
	position: relative;
	width: 188px;
	z-index: z(search-visible-elements);

	@include media(md-down) {
		height: auto;
		width: auto;
	}

	.b-theme_babies & {
		width: 192px;

		@include media(md-down) {
			width: auto;
		}
	}

	.b-theme_hmv & {
		width: 192px;

		@include media(md-down) {
			width: auto;
		}
	}

	.b-theme_roomsandspaces & {
		@include media(md-down) {
			margin: 0 auto;
			display: block;
		}
	}

	.mobile-logo {
		display: none;
	}

	.desktop-logo {
		display: block;
	}

	@include media(md-down) { // stylelint-disable-line
		.desktop-logo {
			display: none;
		}

		.mobile-logo {
			display: block;
		}
	}

	img {
		display: inline-block;
		vertical-align: middle;
	}
}
