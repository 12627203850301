.b-confirm_gift_modal {
	&-checked_icon,
	&-title {
		display: inline-block;
		vertical-align: middle;
	}

	&-content {
		text-align: center;

		p {
			margin-bottom: rh(3);
		}
	}

	&-list {
		list-style: decimal;
		list-style-position: inside;
		margin: 0 auto rh(4);
		max-width: 300px;
		text-align: left;

		> div {
			display: list-item;
		}
	}
}
