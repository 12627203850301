// Thank you Baby registry page
.b-gift_thanks {
	&-buttons {
		@include media(md-up) {
			display: flex;
			margin: 0 auto;
			max-width: 600px;

			.b-button + .b-button {
				margin-left: rh(2);
			}
		}

		@include media(sm) {
			.b-button + .b-button {
				margin-top: rh(2);
			}
		}
	}

	&-wrapper {
		padding: 30px 0;

		@include media(sm) {
			margin-top: 100px;
		}
	}
}
