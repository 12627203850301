.b-product_line_item {
	display: flex;
	justify-content: space-between;
	position: relative;
	user-select: none;

	&-image {
		display: block;
		max-width: 100px;
		min-width: 100px;
		text-decoration: none;
	}

	&-main {
		font-size: 14px;
		padding: 0 rh(5) rh(5) rh(5);
		text-align: left;
		width: 100%;
	}

	&-picture {
		@include g-product-image;

		border-radius: $global-radius;
		height: 100px;
		max-width: 100px;
		min-width: 100px;
		padding: 0;

		img {
			@include g-product-image(_img);
		}
	}

	// Details
	&-title {
		font-size: 16px;
		margin-bottom: rh(3);

		.b-product_line_item.m-minicart & {
			padding-right: rh(6); // space for close button
		}
	}

	&-title_link {
		color: $color-text;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	&-price {
		bottom: rh(2);
		position: absolute;
		right: 0;

		.b-price {
			display: none;
		}
	}

	&-details {
		.align-items-start {
			background: none;
			padding: 0;
		}
	} // could be used as space for price if needed

	&-details_item {
		margin: rh(2 0);
	}

	&-details_value {
		font-weight: bold;

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-details_qty {
		font-weight: normal;
	}

	&-promotion {
		color: $color-red;
		font-weight: bold;
		margin: rh(2 0);
	}

	// Remove
	&-remove {
		position: absolute;
		right: rh(-5);
		top: rh(-5);
	}

	&-remove_btn {
		background: none;
		color: $color-brand76;
		height: auto;
		line-height: 1;
		padding: rh(5);

		&:hover {
			background: none;
			color: $color-blue;
		}

		.b-theme_babies & {
			background: none;

			&:hover {
				background: none;
				color: $color-brand;
			}
		}

		.b-theme_hmv & {
			background: none;

			&:hover {
				background: none;
				color: var(--color-brand);
			}
		}
	}
}

.b-card .b-product_line_item p {
	font-size: inherit;
}
