/* stylelint-disable */
.b-product_wishlist {
	&-link {
		position: absolute;
		right: 2px;
		top: 2px;
		z-index: 1;

		.b-product_cart & {
			margin-top: -2px;
			padding-left: 20px;
			position: static;
		}

		&.b-product_giftregistry_guest {
			pointer-events: none;
		}
	}

	&-icon {
		display: block;
		fill: $color-red;
		height: 20px;
		width: 20px;

		.b-theme_hmv & {
			fill: var(--color-brand);
		}

		.b-product_details-name_wrapper & {
			height: 30px;
			width: 30px;
		}
	}

	&-empty {
		.b-product_wishlist-registered & {
			display: none;
		}
	}

	&-filled {
		display: none;

		.b-product_wishlist-registered & {
			display: block;
		}

		.b-product_giftregistry_guest & {
			display: block;
		}
	}

	&-blocked {
		cursor: auto;
		opacity: 0.5;
		pointer-events: none;
	}
}
