.h-vis {
	@include hide(visually);
}

.h-hidden {
	display: none !important; //stylelint-disable-line
}

.h-busy {
	height: 100%;
	position: relative;
	width: 100%;

	&::after {
		animation: fade-in-overlay ease 1.4s;
		background-color: $color-white;
		content: '';
		inset: 0;
		opacity: 0.4;
		pointer-events: none;
		position: absolute;
		z-index: z(blocking-busy-overlay);
	}
}

.h-uppercase {
	text-transform: uppercase;
}

.h-shake {
	animation: shake 0.5s linear;
}
