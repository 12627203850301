.b-header_button {
	appearance: none;
	background-color: $color-white;
	border-radius: 100%;
	color: $color-brand;
	display: block;
	font-family: 'Arial', sans-serif;
	height: 40px;
	line-height: 39px;
	margin-left: rh(4);
	min-width: 40px;
	padding: 0;
	position: relative;
	text-align: center;
	user-select: none;
	width: 40px;

	@include media(lg-up) {
		border: 1px solid $color-brand94;
	}

	.b-theme_hmv & {
		@include media(lg-up) {
			border-color: var(--color-neutral-900);
		}
	}

	@include media(md-down) {
		align-items: center;
		display: flex;
		justify-content: center;
		margin-left: rh(1);
	}

	&.m-first {
		margin-left: 0;

		@include media(md-down) {
			border: 2px solid $color-brand;
		}
	}

	&.no-border {
		border: 0;
	}

	&.m-shadowed {
		@include media(md-up) {
			box-shadow: $depth-1;

			&[aria-expanded='true'],
			&:hover {
				background: $color-brand;
				border-color: $color-brand;
				color: $color-white;
			}

			&[disabled] {
				background: $color-white;
				color: $color-brand;
				cursor: default;
			}
		}
	}

	&-icon {
		vertical-align: middle;
	}

	&-wishlist {
		@include media(md-down) {
			display: none;
		}

		svg {
			fill: $color-brand;
		}

		&:hover {
			.b-header_button {
				background: $color-brand;
			}

			svg {
				fill: $color-white;
			}
		}
	}
}
