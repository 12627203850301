.b-locator_search {
	margin: 0 auto rh(13);
	max-width: 736px;

	@include media(sm) {
		margin: 0 auto rh(10);
	}

	&-title {
		font-family: $font-serif;
		font-size: 30px;
		font-weight: bold;
		margin: rh(11) auto;
		text-align: center;
		text-transform: uppercase;

		@include media(md-down) {
			font-size: 24px;
		}

		@include media(sm) {
			margin: rh(9) auto;
		}

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}
	}

	&-header {
		padding: 10px 0;
	}

	&-form {
		display: flex;
		justify-content: space-between;

		@include media(sm) {
			flex-direction: column;
		}

		&.form-group label::after {
			display: none;
		}
	}

	&-location,
	&-radius {
		width: 352px;

		@include media(md) {
			width: 330px;
		}

		@include media(sm) {
			margin-bottom: rh(4);
			width: 100%;
		}
	}

	&-select {
		box-shadow: $global-box-shadow;
		padding: 0 30px 0 16px;
	}

	&-divider {
		font-size: 12px;
		font-weight: 900;
		margin: rh(4) auto;
		position: relative;
		text-align: center;

		&::before {
			background: $color-divider;
			content: '';
			height: 1px;
			left: 50%;
			margin-left: -10px;
			position: absolute;
			top: 45%;
			transform: translate(-20px, 0);
			width: 15px;
		}

		&::after {
			background: $color-divider;
			content: '';
			height: 1px;
			position: absolute;
			top: 45%;
			transform: translate(5px, 0);
			width: 15px;
		}

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-location_panel,
	&-radius_panel {
		label {
			display: block;
			font-size: 16px;
			font-weight: 900;
			margin-bottom: rh(1);
		}
	}

	&-location {
		padding: 0 20px 0 0;

		input::placeholder {
			font-size: 14px;

			@include media(sm) {
				font-size: 12px;
			}
		}

		&.m-required {
			label {
				display: inline-block;
				position: relative;

				&::after {
					@include g-asterisk;

					position: absolute;
					right: -10px;
					top: 0;
				}
			}
		}
	}

	&-location_panel {
		margin-bottom: rh(4);
	}

	&-group {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	&-group_input {
		margin-right: 10px;
		width: 65%;

		@include media(sm) {
			width: 50%;
		}

		.b-product_details-zip_code_block & {
			margin-right: 0;
			width: inherit;
		}
	}

	&-find-location-wrapper {
		svg {
			color: $color-brand;
		}
	}

	&-btn_find {
		height: auto;
		line-height: 24px;
		margin-top: 10px;
		min-height: 40px;
		white-space: normal;
		width: 50%;

		@include media(sm) {
			margin: rh(2 0);
		}

		svg {
			fill: $color-brand;
		}

		span {
			display: none;
		}
	}

	&-find-by-location {
		color: $color-brand;
		display: inline-block;
		margin: 0 0 10px 3px;
	}

	&.m-pdp {
		margin: rh(0);

		.b-locator_search-location {
			margin-bottom: 0;
			width: 100%;
		}

		.b-locator_search-form {
			flex-direction: column;
			margin: rh(0);
		}

		.b-locator_search-radius {
			width: 100%;
		}
	}
}
