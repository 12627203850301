$transition: ease 0.5s;
$arrow-icon-new: 'data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="21"><path fill="white" fill-rule="evenodd" d="M11.568 11.001l-9.035 8.614a1.525 1.525 0 0 1-2.081 0 1.357 1.357 0 0 1 0-1.984l7.994-7.621L.452 2.388a1.357 1.357 0 0 1 0-1.983 1.523 1.523 0 0 1 2.081 0l9.035 8.613c.287.274.431.632.431.992 0 .358-.144.718-.431.991z"/></svg>';

.n-brands_menu {
	font-size: 14px;
	margin: 0 auto;
	width: 100%;

	// Note this alphabet is used both in main menu and Brand landing menu
	&-alphabet {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 32px;
		text-align: center;

		@include media(md-down) {
			justify-content: flex-start;
			text-align: left;
			margin: 0 2px 32px;
		}

		&.m-nav {
			@include media(md-down) {
				margin: rh(5 0 3);
				padding: 0 calc(100% - 265px);
			}
		}
	}

	&-button {
		border: 1px solid $color-link;
		border-radius: $global-radius;
		box-shadow: none;
		color: $color-link;
		display: inline-block;
		font-size: 14px;
		font-weight: bold;
		height: 25px;
		line-height: 27px;
		margin: 7px;
		text-align: center;
		text-decoration: none;
		transition: $motion-ease;
		transition-property: box-shadow;
		white-space: nowrap;
		width: 25px;

		@include media(md-down) {
			margin: 5px;
			width: 25px;
			height: 25px;
		}

		@include media(sm) {
			margin: 3px;
		}

		&.new-layout {
			color: $color-white;
			border: 1px solid $color-white;
			line-height: 24px;

			&:visited {
				color: $color-white;
			}
		}

		&:visited {
			color: $color-link;
		}

		&:hover {
			background-color: $color-link;
			box-shadow: $depth-1;
			color: $color-white;
			text-decoration: none;
		}

		&:active,
		&:focus {
			background-color: $color-link;
			color: $color-white;
		}

		&.m-disabled {
			border-color: $color-grey77;
			color: $color-grey77;
			pointer-events: none;
		}

		.n-theme_rle & {
			font-weight: 500;
		}
	}

	&-brands {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		margin: 20px 0 0;
		text-align: center;

		@include media(md-down) {
			margin: 20px 0;
		}
	}

	&-brand {
		display: inline-block;
		height: 100%;
		margin: rh(2.5);
		overflow: hidden;
		position: relative;
		text-decoration: none;
		width: 70px;

		@include media(md-down) {
			margin: rh(2);
			width: 68px;
		}

		@include media(sm) { // stylelint-disable-line
			margin: rh(1.8);
			width: 60px;
		}

		&:nth-child(-n+9) {
			display: inline-block;
		}

		&::before {
			border-radius: $global-radius;
			box-shadow: $depth-1;
			content: '';
			inset: 0;
			opacity: 0;
			position: absolute;
			transition: opacity $motion-ease;
		}

		&:hover {
			border-color: $color-brand;

			&::before {
				opacity: 1;
			}
		}

		&:active {
			&::before {
				// stylelint-disable-line

				box-shadow: none;
			}

			// stylelint-disable-line
		}

		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&-footer {
		margin: rh(4 0 5);
		text-align: left;
		background-size: 8px;
		opacity: 0.8;

		.b-theme_hmv & {
			opacity: 1;
		}

		&.brand-link {
			margin: rh(4 0 5);
			text-align: center;
			text-decoration: none;
			background: transparent;
			background-size: 0;
			opacity: 0.8;
		}
	}

	&-title {
		@include g-hamburger-button; // stylelint-disable-line

		background: $color-red;
		color: $color-white;
		font-size: 14px;
		text-align: center;

		.n-theme_babies & {
			background-color: $color-pink;
		}
	}

	&-ln {
		color: $color-white;
		font-weight: bold;
		text-decoration: none;
		text-transform: uppercase;
		user-select: none;
		opacity: 0.8;

		.b-theme_hmv & {
			opacity: 1;
		}

		&:hover {
			text-decoration: none;
		}
	}
}
