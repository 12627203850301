/// This package address differences of default styling through all major browsers.
///
/// Best practice not include this package *globally* until we use HTML-tags for UI components.
///
/// @group packages
/// @access public
/// @example scss - Usage
/// .b-form {
///   @include normalize-forms();
/// }

@mixin normalize-forms() {
	// stylelint-disable
	button,
	input,
	select,
	textarea {
		margin: 0; // Addresses margins set differently in Firefox 4+, Safari 5, and Chrome
		padding: 0;
		vertical-align: baseline;
	}

	input {
		&[type=button],
		&[type=submit],
		&[type=reset] {
			-webkit-appearance: button; //Corrects inability to style clickable 'input' types in iOS
		}

		&[type=checkbox],
		&[type=radio] {
			box-sizing: border-box; // Addresses box sizing set to `content-box` in IE 8/9.
			padding: 0; // Removes excess padding in IE 8/9.
		}

		&[type=checkbox] {
			vertical-align: baseline;
		}
	}

	button,
	input {
		&[disabled] {
			cursor: default;
		}

		&::-moz-focus-inner {
			border: none; // Removes inner padding and border in FF3+ www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
			padding: 0;
		}

		&:-moz-focusring {
			outline: none !important;
		}
	}

	input::-webkit-inner-spin-button {
		display: none;
	}

	input::-ms-clear {
		display: none;
	}

	// stylelint-enable
}
