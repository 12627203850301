.b-spin_button {
	display: flex;
	user-select: none;

	&-input {
		@include g-input;

		border-color: $color-brand;
		box-shadow: none;
		margin: rh(0 2);
		text-align: center;
		width: 85px;

		.b-sticky-footer & {
			width: 50px;
		}

		.b-theme_hmv & {
			border-color: var(--color-neutral-700);
		}

		&:hover {
			box-shadow: $depth-1;
		}
	}

	&-button {
		font-size: 30px;
		height: 40px;
		padding: 0;
		width: 40px;

		.b-theme_rle & {
			align-items: center;
			display: flex;
			justify-content: center;
			overflow: hidden;
		}

		.b-theme_hmv & {
			border-color: var(--color-neutral-700);
		}

		&.m-decrement {}// stylelint-disable-line block-no-empty

		&.m-increment {}// stylelint-disable-line block-no-empty
	}

	&-action_symbol {
		.b-theme_rle & {
			height: 42px;
		}
	}

	&.m-full_width {
		.b-spin_button-input {
			flex-grow: 1;
		}

		.b-spin_button-button {
			min-width: 40px;
		}
	}
}
