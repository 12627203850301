.b-utility_domain_switcher {
	--color-primary: var(--color-brand);
	background-color: var(--color-primary);
	height: calc(100% - 10px);
	max-height: 60px;
	overflow: hidden;
	transition: max-height 0.3s ease;

	.b-theme_hmv & {
		--color-primary: var(--color-neutral-0);
	}

	@include media(md-down) {
		display: none;
	}

	.l-header-sticky & {
		max-height: 0;
	}

	&-inner {
		@include site-width;

		align-items: center;
		display: flex;
	}

	&-img_hover {
		display: none;
	}

	&-link {
		align-items: center;
		color: $color-white;
		display: flex;
		height: 60px;
		justify-content: center;
		min-width: 202px;

		path {
			fill: $color-white;
		}

		.path-four {
			fill: $color-brand;
		}

		> div {
			align-items: center;
			display: flex;
		}

		&.m-active {
			background-color: $color-white;

			&.m-toysrus {
				path {
					fill: $color-blue;
				}

				.path-four {
					fill: $color-white;
				}
			}

			&.m-babiesrus {
				path {
					fill: $color-purple;
				}
			}

			&.m-hmv {
				path {
					fill: var(--color-primary);
				}
			}

			&.m-rle {
				path {
					fill: $color-black;
				}
			}

			&.m-roomsandspaces {
				path {
					fill: $color-rooms;
				}

				path.d { // stylelint-disable-line
					fill: $color-teal; // stylelint-disable-line
				}
			}
		}

		&.m-rle:not(.m-active) {
			path {
				stroke: $color-white;
			}
		}

		&:not(.m-active) {
			transition: background-color $motion-ease;
		}

		&:hover:not(.m-active) {
			.b-theme_toys & {
				background-color: mix($color1:$color-blue, $color2:$color-white, $weight:80%);
			}

			.b-theme_babies & {
				background-color: mix($color1:$color-purple, $color2:$color-white, $weight:80%);
			}

			.b-theme_hmv & {
				background-color: mix($color1:$color-black, $color2:$color-white, $weight:80%);
			}

			.b-theme_rle & {
				background-color: mix($color1:$color-grey, $color2:$color-white, $weight:80%);
			}

			.b-theme_roomsandspaces & {
				background-color: mix($color1: $color-rooms, $color2:$color-white, $weight:80%);
			}
		}
	}
}
