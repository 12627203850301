.b-utility_navigation {
	&-list {
		display: flex;

		@include media(md-down) {
			flex-direction: column;
		}
	}

	&-link {
		border-right: 1px solid rgba($color-white, 0.3);
		color: rgba($color-white, 0.8);
		padding: rh(0 6);
		text-decoration: none;
		text-transform: uppercase;

		&:hover {
			color: $color-white;
			text-decoration: underline;
		}
	}

	&-item {
		&:last-child {
			.b-utility_navigation-link {
				border-right: none;
			}
		}
	}
}
