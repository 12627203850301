.b-promo {
	background-color: $color-brand97;
	border-radius: $global-radius;
	display: block;
	overflow: hidden;
	width: 100%;

	picture,
	img {
		width: 100%;
	}

	img {
		display: block;
		height: 100%;
		margin: auto;
		object-fit: cover;
	}

	&.m-narrow {
		max-height: 140px;
	}

	&.m-medium {
		max-height: 240px;
	}

	.m-no_results & {
		margin: rh(5) auto;
		max-width: 860px;
	}
}

a.b-promo { // stylelint-disable-line
	transition: opacity $motion-ease;

	&:hover {
		opacity: 0.8;
	}
}
