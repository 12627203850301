.b-suggestions {
	animation: fade-in ease 1s;
	left: 0;
	max-height: 70vh;
	overflow-y: auto;
	padding: rh(10 0 3 1);
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity ease 1s;
	z-index: z(components, header-search, suggestions);

	@include media(md-down) {
		margin: rh(5 0);
		max-height: 65vh;
		overflow-y: scroll;
	}

	// Layout
	&-guess {
		margin-bottom: rh(10);
	}

	&-two_columns {
		display: flex;

		@include media(md-down) {
			flex-direction: column;

			&::-webkit-scrollbar {
				width: 5px !important;// stylelint-disable-line
			}
		}
	}

	&-results {
		min-width: 34%;
		width: 100%;

		& + & {
			border-left: 1px solid $color-divider;
			padding: rh(0 7);

			@include media(md-down) {
				border-left: none;
				border-top: 1px solid $color-divider;
				padding: rh(7 0);
			}

			.b-theme_hmv & {
				border: 0;
			}
		}

		&.m-products {
			border: none;
			min-width: 66%;
			padding: rh(0 7 0 0);

			@include media(md-down) {
				padding: rh(0 1);
				width: 100%;
			}
		}

		& + &.m-categories {
			padding: rh(0 0 0 8);

			@include media(md-down) {
				padding: rh(7 1 12);
			}
		}
	}

	// Content
	.m-focused {
		outline: rh(1) solid $color-brand76;
	}

	&.m-loading {
		opacity: 0.3;
		pointer-events: none;
	}

	&-title {
		color: $color-text;
		font-size: 16px;
		font-weight: 900;
		margin-bottom: rh(6);
		text-transform: uppercase;

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-title_lower_case {
		text-transform: none;
	}

	&-secondary_title {
		color: $color-grey44;
		font-family: $font-sans;
		font-size: 16px;
		font-weight: normal;
		margin-bottom: rh(6);
	}

	&-result_list_link {
		color: $color-text;
		display: inline-block;
		margin-bottom: rh(2);
		text-decoration: none;

		&:hover {
			color: $color-link;
			text-decoration: underline;
		}
	}

	&-oblique_link {
		font-size: 16px;
		font-style: oblique;
		font-weight: 900;

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&::-webkit-scrollbar {
		width: 5px !important;// stylelint-disable-line
	}
}

.no-scroll {
	overflow: hidden;
	position: relative;
}
