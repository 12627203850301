@mixin g-asterisk {
	background: url('../../img/asterisk.svg') 0 0 no-repeat;
	background-size: 5px 5px;
	content: '';
	display: inline-block;
	height: 5px;
	margin-left: 2px;
	vertical-align: top;
	width: 5px;
}
