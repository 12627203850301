.header-postal-code_delivery {
	justify-content: flex-end;
}

.postal-code-delivery_inner {
	cursor: pointer;
	padding-bottom: 1rem;

	.b-title {
		font-size: 14px; // Henrique - the pattern will be px?
		font-weight: bold;

		.selected-preference {
			color: var(--color-brand);
			font-weight: bold;
			text-transform: uppercase;
		}

		@media screen and (width >= 768px) {
			font-size: 16px; // Henrique - the pattern will be px?
		}
	}
}

.summary-postalcode {
	color: $color-grey;

	.postal-code-delivery_inner {
		cursor: default;
	}

	.b-title {
		.selected-preference {
			color: $color-grey;
		}
	}
}
