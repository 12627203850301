.b-cart_baby_registry {
	align-items: center;
	background: $color-brand97;
	display: flex;
	font-weight: 900;
	padding: rh(2 7);
	text-align: left;

	@include media(sm) {
		margin-top: rh(7);
	}

	&-icon_wrap {
		margin-right: rh(2);
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
