@use 'sass:math';

@mixin global-animation() {
	@at-root {
		@keyframes fade-in {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}

		@keyframes fade-in-overlay {
			0% {
				opacity: 0;
			}

			100% {
				opacity: 0.4;
			}
		}

		@keyframes slide-from-bottom {
			0% {
				opacity: 0;
				transform: translateY(40%);
			}

			100% {
				opacity: 1;
				transform: translateY(0);
			}
		}

		@keyframes rotate {
			0% {
				transform: rotate(0deg);
			}

			100% {
				transform: rotate(270deg);
			}
		}

		$offset: 187;

		@keyframes dash {
			0% {
				stroke-dashoffset: $offset;
			}

			50% {
				stroke-dashoffset: math.div($offset, 4);
				transform: rotate(135deg);
			}

			100% {
				stroke-dashoffset: $offset;
				transform: rotate(450deg);
			}
		}

		@keyframes highlight {
			0% {
				color: $color-text;
			}

			50% {
				color: $color-yellow;
			}

			100% {
				color: $color-text;
			}
		}

		@keyframes shake {
			8%,
			41% {
				transform: translateX(-10px);
			}

			25%,
			58% {
				transform: translateX(10px);
			}

			75% {
				transform: translateX(-5px);
			}

			92% {
				transform: translateX(5px);
			}

			0%,
			100% {
				transform: translateX(0);
			}
		}

		@media (prefers-reduced-motion) {
			* { /* stylelint-disable-line selector-max-universal */
				animation: none !important; /* stylelint-disable-line declaration-no-important */
				transition: none !important; /* stylelint-disable-line declaration-no-important */
			}
		}
	}
}
