@mixin g-close-button() {
	background: transparent;
	border: none;
	color: $color-brand76;
	cursor: pointer;
	padding: rh(3);

	.b-theme_hmv & {
		color: var(--color-neutral-700);
	}

	&:hover {
		color: $color-brand;
	}

	&:active {
		transform: scale(0.95);
	}

	svg {
		display: block;
	}
}
