/*md

# Radio box

```html_example
<label class="b-radio_box">
    <input class="b-radio_box-input" name="donate-value" value="donate-1" checked type="radio">
    <span class="b-radio_box-label">
        <span class="b-radio_box-label_inner">$1</span>
    </span>
    <span class="b-radio_box-decoration"></span>
</label>

<label class="b-radio_box">
    <input class="b-radio_box-input" name="donate-value" value="donate-5" type="radio">
    <span class="b-radio_box-label">
        <span class="b-radio_box-label_inner">$5</span>
    </span>
    <span class="b-radio_box-decoration"></span>
</label>

<label class="b-radio_box">
    <input class="b-radio_box-input" name="donate-value" value="donate-10" type="radio">
    <span class="b-radio_box-label">
        <span class="b-radio_box-label_inner">$10</span>
    </span>
    <span class="b-radio_box-decoration"></span>
</label>

<label class="b-radio_box">
    <input class="b-radio_box-input" name="donate-value" value="donate-20" type="radio">
    <span class="b-radio_box-label">
        <span class="b-radio_box-label_inner">$20</span>
    </span>
    <span class="b-radio_box-decoration"></span>
</label>

<label class="b-radio_box">
    <input class="b-radio_box-input" name="donate-value" value="donate-custom" id="donate-custom" type="radio">
    <span class="b-radio_box-label">
        <label class="b-radio_box-label_inner" for="donate-custom">Other</label>
    </span>
    <span class="b-radio_box-decoration"></span>
    <span class="b-radio_box-text_input">
        $
        <input
            name="custom-donate-value"
            type="number"
            aria-label=""
            onfocus="document.getElementById('donate-custom').checked=true"
        />
        .00
    </span>
</label>
```
*/

.b-radio_box {
	align-items: baseline;
	cursor: pointer;
	display: flex;
	margin-bottom: rh(2);
	padding: rh(2 3);
	position: relative;
	user-select: none;

	&-input {
		@include g-radio(_input);

		position: relative;
		vertical-align: middle;
		z-index: z(components, radio-box, content);

		&:checked {
			@include g-radio(_input-checked);
		}

		&:hover {
			@include g-radio(_input-hover);
		}

		&:checked:hover {
			@include g-radio(_input-checked-hover);
		}

		&[disabled] {
			@include g-radio(_input-disabled);
		}
	}

	&-label {
		display: inline-block;
	}

	&-label_inner {}// stylelint-disable-line block-no-empty

	&-decoration {
		border: 1px solid $color-brand94;
		border-radius: $global-radius;
		inset: 0;
		position: absolute;
		z-index: z(components, radio-box, decoration);
	}

	// Checked state
	.b-radio_box-input:checked + .b-radio_box-label {
		.b-radio_box-label_inner {
			position: relative;
			z-index: z(components, radio-box, content);
		}
	}

	.b-radio_box-input:checked + .b-radio_box-label + .b-radio_box-decoration {
		background-color: $color-brand97;
		border-color: $color-brand;
		box-shadow: $depth-1;
	}

	// Stack modifications
	&.m-stack {
		margin-bottom: rh(4);
		padding: rh(3);
	}

	// Donation form on static pages. We have an input inside one of checkbox
	&-text_input {
		display: inline-block;
		font-weight: bold;
		margin-left: rh(3);
		position: relative;
		z-index: z(components, radio-box, content);

		input {
			@include g-input;

			text-align: center;
			width: 60px;

			&:hover {
				@include g-input(_hover);
			}

			&.is-invalid { // stylelint-disable-line
				@include g-input(_invalid);
			}
		}
	}
}
