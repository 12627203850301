.b-form_reset_password {
	&-body {
		margin-bottom: rh(5);
	}

	&-message {
		margin-bottom: rh(4);
		text-align: center;
	}
}
