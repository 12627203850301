.b-popup_menu {
	background-color: $color-white;
	border-radius: $global-radius;
	box-shadow: $depth-2;
	left: 0;
	max-width: 200px;
	opacity: 0;
	position: absolute;
	right: 0;
	transform: translateY(10px);
	transition: $motion-ease;
	transition-property: visibility, opacity, transform;
	visibility: hidden;
	z-index: z(popup-menu);

	@supports (filter: drop-shadow(0 0 10px rgba(0,0,0,0.5))) {
		box-shadow: none;
		filter: drop-shadow($depth-2);
	}

	@include media(sm) {
		display: none;
	}

	&.m-active {
		opacity: 1;
		transform: translateY(0);
		visibility: visible;
	}

	&-item {
		margin-bottom: 10px;
		text-align: left;
		width: 100%;
	}

	&-tail {
		background-clip: content-box;
		background-image: linear-gradient(to top right, transparent 45%, white 45%);
		background-origin: border-box;
		box-shadow: 4px -4px 7px -2px hsla($color-brand-raw, 0.3);
		content: '';
		height: 14px;
		left: auto !important; /* stylelint-disable-line */
		margin-left: -16px;
		position: absolute;
		right: 30px;
		top: -7px;
		transform: rotate(-45deg);
		width: 14px;

		@supports (filter: drop-shadow(0 0 10px rgba(0,0,0,0.5))) {
			box-shadow: none;
		}
	}

	&-list {
		margin: rh(6);
		overflow: hidden;
	}

	a {
		color: $color-text;
		display: block;
		text-transform: uppercase;

		&:hover {
			color: $color-link;
			text-decoration: underline;
		}
	}
}
