.b-country_selector {
	margin-left: 40px;
	white-space: nowrap;

	@include media(md-down) {
		background-color: $color-brand;
		border: 1px solid $color-brand;
		border-radius: $global-radius;
		display: flex;
		margin: 0;
		overflow: hidden;
		width: 100%;
	}

	.b-footer & {
		@include media(lg-up) {
			display: none;
		}

		@include media(md-down) {
			display: none;
		}
	}

	&-locale {
		appearance: none;
		background-color: transparent;
		border: none;
		color: rgba($color-white, 0.4);
		cursor: pointer;
		display: inline-block;
		font-family: $font-sans;
		font-size: 14px;
		font-weight: 900;
		margin-top: -1px;
		padding: rh(0 2);
		text-decoration: none;
		user-select: none;
		width: 50%;

		.b-theme_hmv & {
			color: $color-white;
		}

		@include media(md-down) {
			background-color: rgba($color-white, 0.4);
			color: $color-white;
			height: 30px;
			line-height: 32px;
			margin-top: 0;
			text-align: center;
		}

		.b-theme_rle & {
			font-weight: 500;
		}

		.b-footer & {
			color: $color-brand;
			opacity: 0.8;
		}

		&:first-child {
			border-right: 1px solid $color-brand;
		}

		&:hover {
			color: $color-white;
			text-decoration: underline;

			.b-footer & {
				color: $color-brand;
			}
		}

		&.m-active_locale {
			color: rgba($color-white, 0.8);
			cursor: default;

			.b-theme_hmv & {
				color: $color-white;
			}

			@include media(md-down) {
				background-color: transparent;
			}

			.b-footer & {
				color: $color-brand;
				opacity: 1;
			}

			&:hover {
				text-decoration: none;
			}
		}
	}
}

.header-country-selector {
	display: inline-flex;
	justify-content: flex-end;
	padding-bottom: 1rem;

	@include media(md-down) {
		display: none;
	}
}

.h-country_selector {
	&-locale {
		appearance: none;
		background-color: transparent;
		border: none;
		color: $color-brand;
		cursor: pointer;
		display: inline-block;
		font-family: $font-sans;
		font-size: 16px;
		font-weight: 900;
		margin-top: -1px;
		padding: rh(0 2);
		text-decoration: none;
		user-select: none;

		.b-theme_rle & {
			font-weight: 500;
		}

		&:hover {
			text-decoration: none;
		}
	}
}
