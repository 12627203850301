// Bootstrap alerts styleing to not change all forms with it messages
.alert { // stylelint-disable-line
	background-color: lighten($color-green, 44%);
	border-radius: $global-radius;
	color: $color-text;
	display: block;
	font: 16px/1.38 $font-sans;
	margin: rh(5 0);
	padding: rh(3 4);
	text-align: center;

	strong {
		font-weight: normal;
	}
}

.alert-danger {
	background-color: lighten($color-red, 50%);
}
