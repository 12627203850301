.b-footer_nav {
	@include site-width;

	display: flex;
	flex-direction: row;
	line-height: 1.2;

	@include media(md) {
		flex-wrap: wrap;
	}

	@include media(sm) {
		@include g-accordion-styles;

		flex-direction: column;
		padding-left: 0;
		padding-right: 0;
	}

	> div {
		width: 20%;

		@include media(lg-up) {
			padding-bottom: rh(12);
			padding-top: rh(12);

			&:not(:last-child) {
				padding-right: rh(7);
			}
		}

		@include media(md) {
			padding-bottom: rh(10);
			padding-right: rh(7);
			width: 33.3333%;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				padding-top: rh(5);
			}

			&:nth-child(3n) {
				padding-right: 0;
			}
		}

		@include media(sm) {
			width: 100%;
		}
	}

	&-block {
		@include media(sm) {
			@include g-accordion-styles(_item);

			border-bottom: 1px solid $color-brand88;
		}
	}

	&-title {
		border-bottom: 2px solid $color-brand94;
		color: $color-text;
		display: flex;
		font-family: $font-serif;
		font-size: 18px;
		text-align: left;
		text-transform: uppercase;

		.b-theme_hmv & {
			font-family: $font-sans;
			border: 0;
			color: var(--color-brand);
		}

		@include media(md-up) {
			margin-bottom: rh(2);
			min-height: 60px;
			padding-bottom: rh(3);

			svg {
				display: none;
			}
		}

		@include media(md) {
			align-items: flex-end;
		}

		@include media(sm) {
			@include g-accordion-styles(_control);

			border-bottom: 0;
			font-size: 14px;
			font-weight: bold;
			line-height: 52px;
			padding: rh(0 4);

			&[aria-expanded='true'] {
				@include g-accordion-styles(_control_expanded);
			}
		}

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}

		a {
			color: $color-text;

			@include media(sm) {
				color: $color-brand;
			}

			.b-theme_hmv & {
				color: inherit;

				@include media(sm) {
					color: inherit;
				}
			}
		}
	}

	&-container {
		cursor: pointer;
		display: flex;
		flex-direction: column;

		@include media(sm) {
			@include g-accordion-styles(_content);
		}

		&.m-expanded {
			@include media(sm) {
				@include g-accordion-styles(_content_expanded);
			}
		}
	}

	&-link {
		color: $color-text;
		display: block;
		padding: rh(2 0);
		text-align: left;

		&:focus,
		&:hover {
			text-decoration: underline;
		}
	}
}
