/* stylelint-disable */
.b-wishlist {
	&_container {
		.b-breadcrumbs {
			text-align: left;
		}

		.h-country_selector {
			display: none;
		}
	}

	&_title {
		font: 30px/1.2 $font-serif;
		margin: 20px;

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}
	}

	&_grid {
		display: flex;
		flex-wrap: wrap;
		height: auto;
		justify-content: flex-start;
		margin: 28px 0;
	}

	&_item-inner {
		position: relative;
	}

	&_item {
		margin-bottom: rh(9);
		margin-right: 27px;
		width: calc((100% - 56px) / 3);

		@include media(md-up) {
			&:nth-of-type(3n) {
				margin-right: 0;
			}
		}

		@include media(lg-up) {
			min-width: 350px;
		}

		@include media(sm) {
			margin-right: 0;
			width: 100%;
		}

		.b-product_availability-msg {
			text-align: left;
		}

		.b-product_details-shipping-container,
		.b-product_details-availability-in-store {
			border: 0;
			box-shadow: none;
			margin: 0;
			padding: 0;
		}

		.b-product_details-shipping_title {
			display: none;
		}
	}

	&_quantity {
		padding: 10px 0;
		text-align: left;

		label {
			display: none;
		}
	}

	&_remove-btn {
		background: 0;
		border: 0;
		color: var(--color-brand52);
		cursor: pointer;
		height: 27px;
		position: absolute;
		right: 0;
		top: 15px;
		width: 27px;
		z-index: 2;

		&:hover {
			color: var(--color-brand);
		}
	}

	&_empty {
		font-size: 16px;
		margin: 20px auto;
	}

	&_show-more {
		margin: 0 auto;
	}
}
