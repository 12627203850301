.b-footer_content {
	background-color: $color-brand97;
	margin-top: rh(23);

	@include media(md-down) {
		margin-top: rh(10);
	}

	&-section {
		@include site-width;
	}

	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding-top: rh(10);
	}

	&-item {
		width: 20%;

		@include media(md) {
			width: 33.3333%;
		}

		@include media(sm) {
			width: 50%;
		}
	}

	&-icon {
		align-items: center;
		background-color: $color-grey44;
		border: 4px solid $color-white;
		border-radius: 50%;
		color: $color-white;
		display: flex;
		fill: currentcolor;
		justify-content: center;
		line-height: 82px;
		margin-bottom: rh(5);
		min-height: 90px;
		min-width: 90px;

		@include media(sm) {
			display: none;
		}

		&.m-red {
			background-color: $color-red;
		}

		&.m-orange {
			background-color: $color-orange;
		}

		&.m-green {
			background-color: $color-green;
		}

		&.m-blue {
			background-color: $color-blue;
		}

		&.m-steel-blue {
			background-color: $color-blue51;
		}

		&.m-purple {
			background-color: $color-purple;
		}

		&.m-yellow {
			background-color: $color-yellow;
		}

		&.m-dimmed_blue {
			background-color: $color-blue51;
		}

		&.m-pink {
			background-color: $color-pink;
		}

		&.m-static_blue {
			background-color: $color-blue;
		}

		&.m-turquoise {
			background-color: $color-turquoise;
		}
	}

	&-title {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: rh(1);

		@include media(sm) {
			&.m-red {
				border-bottom: 3px $color-red solid;
			}

			&.m-orange {
				border-bottom: 3px $color-orange solid;
			}

			&.m-green {
				border-bottom: 3px $color-green solid;
			}

			&.m-blue {
				border-bottom: 3px $color-blue solid;
			}

			&.m-steel-blue {
				border-bottom: 3px $color-blue51 solid;
			}

			&.m-purple {
				border-bottom: 3px $color-purple solid;
			}

			&.m-yellow {
				border-bottom: 3px $color-yellow solid;
			}

			&.m-dimmed_blue {
				border-bottom: 3px $color-blue51 solid;
			}

			&.m-pink {
				border-bottom: 3px $color-pink solid;
			}

			&.m-static_blue {
				border-bottom: 3px $color-blue solid;
			}

			&.m-turquoise {
				border-bottom: 3px $color-turquoise solid;
			}
		}

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-title_line {
		@include solid-text(new_line);
	}

	&-text_line {
		@include solid-text(new_line);
	}

	&-link {
		align-items: center;
		color: $color-text;
		display: flex;
		flex-direction: column;
		font-family: $font-sans;
		margin-bottom: rh(10);
		text-align: center;

		&:hover {
			text-decoration: none;

			.b-footer_content-text {
				text-decoration: underline;
			}

			.b-footer_content-icon {
				background-color: $color-white;
				border: 4px solid;

				&.m-red {
					border-color: $color-red;
					color: $color-red;
				}

				&.m-orange {
					border-color: $color-orange;
					color: $color-orange;
				}

				&.m-green {
					border-color: $color-green;
					color: $color-green;
				}

				&.m-blue {
					border-color: $color-blue;
					color: $color-blue;
				}

				&.m-steel-blue {
					border-color: $color-blue51;
					color: $color-blue51;
				}

				&.m-purple {
					border-color: $color-purple;
					color: $color-purple;
					fill: currentcolor;
				}

				&.m-yellow {
					border-color: $color-yellow;
					color: $color-yellow;
				}

				&.m-dimmed_blue {
					border-color: $color-blue51;
					color: $color-blue51;
				}

				&.m-pink {
					border-color: $color-pink;
					color: $color-pink;
				}

				&.m-static_blue {
					border-color: $color-blue;
					color: $color-blue;
				}

				&.m-turquoise {
					border-color: $color-turquoise;
					color: $color-turquoise;
				}
			}
		}
	}
}
