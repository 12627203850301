.b-notifier {
	animation: slide-from-bottom $motion-ease;
	background-color: $color-brand;
	border-radius: $global-radius;
	bottom: rh(4);
	box-shadow: $depth-1;
	color: $color-white;
	display: none;
	left: 0;
	margin: auto;
	padding: rh(2 1);
	pointer-events: none;
	position: fixed;
	right: 0;
	text-align: center;
	width: 400px;
	z-index: z(notifier);

	// hide notifier
	// clip: rect(1px, 1px, 1px, 1px); // stylelint-disable-line
	// overflow: hidden;
	&[aria-hidden='false'] {
		display: block;
	}

	&[aria-hidden='true'] {
		display: none;
	}
}
