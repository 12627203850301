.b-stores_list {
	margin: 0 auto rh(38);
	max-width: 736px;
	padding-top: rh(5);

	@include media(md) {
		margin: 0 auto rh(25);
		padding-top: 0;
	}
}

.b-locator_card {
	background: $color-white;
	border: 1px solid transparent;
	border-radius: $global-radius;
	box-shadow: $depth-2;
	display: flex;
	flex-direction: column;
	justify-content: end;
	margin-bottom: rh(8);
	padding: rh(5 5 7);
	position: relative;

	@include media(sm) {
		padding: rh(5 5 8);
	}

	&.m-selected {
		background: $color-brand97;
		border-color: $color-brand;
	}

	> div {
		position: relative;
	}

	&_selected {
		background-color: $color-brand94;
		border-color: $color-blue;

		> div {
			background: url('../../img/checked.svg') 100% 0 no-repeat;
			background-size: 20px 20px;
		}

		.b-locator_store-right_column {
			margin-top: rh(7);
		}

		.b-locator_store-cta {
			display: none;
		}
	}

	&-noresults_pdp {
		display: none;
	}

	&-noresults {
		font-size: 16px;
		font-weight: 900;
		margin-bottom: rh(10);
		text-align: center;

		@include media(sm) {
			margin: 0 auto rh(10);
			max-width: 274px;
		}

		&.m-popup {
			margin: rh(5) auto;

			.b-locator_card-noresults_main {
				display: none;
			}

			.b-locator_card-noresults_pdp {
				display: block;
			}
		}
	}

	&-noresults_message {
		font-weight: 900;
	}

	&-select_row {
		display: flex;

		input[type=radio] { // stylelint-disable-line
			width: 20px;
		}
	}

	&-title {
		font-size: 16px;
		font-weight: 900;
		margin-bottom: rh(7);
		text-transform: uppercase;
	}

	&-btn {
		margin: rh(10) auto rh(6);
		width: auto;
	}

	&-selected_store {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
	}

	&-remove {
		appearance: none;
		background: transparent;
		border: none;
		cursor: pointer;
		font-family: inherit;
		font-size: 14px;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
