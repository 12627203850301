.b-product_details {
	&-brand,
	&-brand_modal {
		color: $color-brand;
		font-size: 16px;
		font-weight: bold;

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-name_wrapper {
		padding-right: 30px;
		position: relative;
	}

	&-name {
		font-size: 24px;
		font-weight: bold;
		margin-top: rh(1);

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-sku {
		font-size: 14px;
		margin-top: rh(3);
	}

	&-rating {
		margin-top: rh(4);
	}

	&-availability {
		font-size: 16px;
		text-transform: uppercase;
	}

	&-availability-in-store,
	&-shipping-container {
		border: solid 1px var(--color-brand94);
		border-radius: 5px;
		box-shadow: var(--depth-2);
		margin-bottom: 20px;
		margin-top: 20px;
		padding: 20px;
		text-align: left;

		.b-theme_hmv & {
			border: 0;
		}
	}

	&-availability-in-store {
		.b-button {
			&.m-green {
				.b-theme_hmv & {
					@include g-button(_hmv-green);
				}
			}
		}
	}

	&-availability-in-store-inner {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;

		.b-product_availability-msg {
			margin-top: 0;
		}
	}

	&-in-store-button {
		.b-button.m-orange {
			.b-theme_babies & {
				@include g-button(_orange);
			}

			.b-theme_hmv & {
				@include g-button(_brand);
			}
		}
	}

	&-pickup-label,
	&-shipping_text {
		font-size: 14px;
	}

	&-pickup-label {
		.b-product_details-zip_code_block & {
			color: $color-red;

			.b-theme_hmv & {
				color: var(--color-brand);
			}
		}
	}

	&-edit-store {
		background: 0;
		border: 0;
		color: $color-black;
		cursor: pointer;
		text-decoration: underline;

		.b-product_details-zip_code_block & {
			margin-bottom: 10px;
		}
	}

	&-door-dash-inner {
		width: 100%;
	}

	&-zip_code_block {
		margin-bottom: -15px;

		&.h-hidden {
			margin-bottom: 0;
		}

		.form-group {
			align-items: flex-start;
			display: flex;
			justify-content: space-between;

			.col { /* stylelint-disable-line */
				margin-bottom: 10px;
				width: 100%;
			}
		}
	}

	&-postcode-error {
		margin-bottom: 10px;
	}

	&-add-to-registry {
		.b-button {
			background: 0;
			color: var(--color-brand52);

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&-door-dash-btn {
		line-height: 1;
		white-space: normal;

		@include media(md-up) {
			margin-right: 30px;
		}
	}

	&-prices {
		.b-price {
			border-top: 1px solid $color-brand94;
			font-size: 18px;
			margin-top: rh(6);
			padding: rh(4 0);

			.b-theme_hmv & {
				border-top: 0;
				padding-top: 0;
			}
		}

		.b-price-label.m-new,
		.b-price-label.m-old {
			display: flex;
		}
	}

	&-variation {
		background-color: $color-background-variation-product-modal;
		border-radius: 20px;
		margin-top: 20px;
		padding: 20px;
	}

	&-cta {
		margin-top: rh(6);

		.b-button {
			&.m-red {
				.b-theme_hmv & {
					@include g-button(_brand);
				}
			}
		}
	}

	&-add_to_registry {
		margin-top: rh(3);
	}

	&-actions {
		margin-top: 15px;

		.b-button { // stylelint-disable-line
			.b-theme_rle & {
				@include media(md) {
					font-size: 14px;
				}
			}
		}
	}

	&-btns_modal {
		.b-button:first-child {
			margin-bottom: rh(4);
		}
	}

	&-baby_registry {
		margin: rh(4 0);
	}

	&-related_items {
		.b-product_related_tabs {
			margin-top: 40px;
		}
	}

	&-store_availability {
		border-top: 1px solid $color-brand94;
		font-size: 14px;
		margin-top: rh(6);
		padding-top: rh(7);

		@include media(md-down) {
			flex-direction: row;
		}
	}

	&-promotions {
		.b-product_promotions {
			border-top: 1px solid $color-brand94;
			margin-top: rh(6);
			padding-top: rh(6);
		}
	}

	&-shipping_info {
		.b-product_shipping_info {
			border-top: 1px solid $color-brand94;
			margin-top: rh(7);
			padding-top: rh(6);

			.b-theme_hmv & {
				border-top: 0;
			}
		}
	}

	&-shipping_days {
		.b-product_promotions-btn {
			display: none;
		}
	}

	&-shipping_title {
		display: block;
		margin-bottom: rh(1);
	}

	&-age {
		.b-product_age {
			border-top: 1px solid $color-brand94;
			margin-top: rh(6);
			padding-top: rh(6);

			.b-theme_hmv & {
				border-top: 0;
				padding-top: 0;
			}
		}
	}

	&-social {
		.b-product_social {
			margin-top: rh(10);
		}
	}

	&-stock_error {
		color: $color-red;
		font-size: 14px;
		margin-top: rh(5);
		text-align: center;

		.b-theme_hmv & {
			color: var(--color-brand);
		}

		.b-product_details & {
			margin-bottom: 15px;
		}
	}

	&-stock_available,
	&-stock_msg {
		color: $color-green;
		font-size: 14px;
		margin-top: rh(5);
		text-align: center;

		.b-product_details & {
			margin-bottom: 15px;
		}
	}

	// stylelint-disable
	&-stock_msg {
		color: $color-black;
	}

	// stylelint-enable
	&-stock_error-blue {
		color: var(--color-brand);
		font-size: 14px;
		margin-top: rh(5);
		text-align: center;

		.b-product_details & {
			margin-top: 12px;
		}
	}

	.local-postal_code_delivery {
		.form-group {
			justify-content: flex-start;
			margin-top: rh(-3);

			.invalid-feedback {
				display: block;
			}
		}

		.b-form_actions {
			order: 2;
			width: 20%;
		}

		.input-wrapper {
			order: 1;
			width: 50%;
		}

		.b-messages {
			order: 3;
		}

		.main-message,
		.b-messages {
			text-align: left;
		}

		.french-cta_wrapper {
			width: 40%;
		}
	}
}
