$color-arrows: url-color-legacy($color-blue);
$color-arrows-rle: url-color-legacy($color-grey);
$color-arrows-hmv: url-color($color-hmv-pink);

.b-product_secondary {
	display: flex;
	flex-direction: column;

	&-section > div {
		margin-bottom: rh(10);

		.b-tile_image_container {
			display: block;
			overflow: hidden;
			padding-bottom: 100%;
			position: relative;
			width: 100%;

			img {
				bottom: 0;
				display: block;
				height: 100%;
				left: 50%;
				object-fit: contain;
				position: absolute;
				top: 0;
				transform: translateX(-50%);
				width: 100%;
			}
		}

		.b-product_tile-inner {
			overflow: hidden;
			padding: 20px;

			.b-product_tile-title {
				font-size: 16px;
				line-height: 1.2;
				margin: rh(3 0);
				max-height: inherit;
				min-height: inherit;
			}

			.b-tile_image_container {
				display: block;
				overflow: hidden;
				padding-bottom: 100%;
				position: relative;
				width: 100%;
			}

			.b-product_tile-title_link {
				color: $color-black;
				font-size: 16px;
				font-weight: normal;
				text-decoration: none;
			}
		}

		.b-section_title {
			background-color: $color-brand97;
			border-radius: $global-radius;
			margin: rh(8 0);
			padding: rh(3 0 2 15);
			position: relative;
			text-align: left;

			.b-theme_hmv & {
				padding-left: 20px;
				background-color: var(--color-neutral-900);
			}

			@include media(sm) {
				margin: rh(8 -4);
				padding: rh(3 5 3 21);
			}

			&-logo {
				left: -10px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);

				@include media(sm) {
					left: 15px;
					transform: translateY(-50%) scale(0.9);
				}

				path {
					fill: $color-brand;
				}
			}

			&-text {
				color: $color-brand;
				font-family: $font-sans;
				font-size: 18px;
				font-weight: 600;
				text-transform: uppercase;

				.b-theme_rle & {
					font-weight: 500;
				}
			}
		}

		.b-featured,
		.b-featured-carousel {
			margin-bottom: rh(-10);

			&-carousel {
				@include g-carousel;

				position: relative;
			}

			&-tile {
				@include g-carousel(_slide);

				max-width: 280px;
				/* stylelint-disable */
				@include media(lg-up) {
					&:hover {
						border: 1px solid $color-brand94;
						border-radius: $global-radius;
						box-shadow: $depth-3;
					}
				}

				.b-product_shipping_info,
				.b-product_tile-promo,
				.b-product_tile-add_to_registry {
					display: none;
				}

				.b-product_tile-title {
					font-weight: normal;
					text-align: left;
				}

				.b-product_tile-ratings {
					color: $color-grey65;
					display: flex;
					margin-bottom: 10px;

					img {
						display: inline-flex;
					}
				}

				.b-price {
					flex-direction: column-reverse;
					justify-content: flex-start;
				}

				.b-price-label {
					display: block;
					font-size: 16px;
					margin-right: 8px;

					&.m-secondary,
					&.m-regular {
						display: none;
					}
				}

				.b-price-sales {
					flex-flow: row wrap;
				}

				.b-price-old {
					flex-flow: row wrap;
					margin: 0;
				}
			}

			.slick-list {
				padding: 13px 0;
			}
		}
	}


	.additional-info-content {
		.additional-info-list {
			margin-bottom: 20px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&-einstein {
		margin-left: auto;
		margin-right: auto;
		width: 100%;

		@include media(md) {
			max-width: 687px;
		}

		@include media(iphone-plus) {
			max-width: 384px;
		}

		@include media(iphone) {
			max-width: 343px;
		}

		@include media(phone-xs) {
			max-width: 320px;
		}

		@include media(iphone-5) {
			max-width: 228px;
		}
	}

	&-section:first-child > div {
		margin-top: rh(10);

		@include media(md-up) {
			margin-top: rh(13);
		}
	}

	&-mobile {
		display: none;

		@include media(sm) {
			display: block;
		}
	}

	.b-product_set_detail & {
		@include media(sm) {
			display: none;
		}
	}

	&-mobile {
		.b-product_set_detail & {
			display: none;

			@include media(sm) {
				display: block;
			}
		}
	}
}

/* custom slick arrows for product secondary and whole carousels */
.slick-arrow {
	background-color: $color-white;
	border: solid 1px $color-brand97;
	border-radius: $global-radius;
	box-shadow: $depth-1;
	cursor: pointer;
	font-size: 0;
	height: 50px;
	margin-top: -25px;
	position: absolute;
	top: 50%;
	transition: all 0.3s ease;
	transition-property: border, background-color;
	width: 50px;
	z-index: 1;

	@include media(md) {
		top: 12vh;
	}

	@include media(sm) {
		height: 40px;
		opacity: 0.6;
		top: 14vh;
		width: 30px;

		.m-order_history & {
			top: 7vh;
		}
	}

	.b-theme_hmv & {
		border-color: var(--color-brand76);
	}

	&:hover {
		border-color: $color-brand;
	}

	&.slick-prev,
	&.slick-next {
		&::before {
			display: inline-block;
			height: 21px;
			width: 12px;

			@include media(sm) {
				height: 14px;
				width: 8px;
			}
		}
	}

	&.slick-prev {
		left: 0;

		@include media(sm) {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			left: -15px;
		}

		.m-order_history & {
			@include media(sm) {
				left: 0;
			}
		}

		&::before {
			content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E'); // TODO: use background

			@include media(sm) {
				content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%278%27%20height%3D%2714%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20fill%3D%27#{$color-arrows}%27%20d%3D%27M.286%206.287L6.31.268a.98.98%200%200%201%201.387%201.386L2.368%206.98l5.329%205.327a.98.98%200%200%201-1.387%201.387L.286%207.674a.98.98%200%200%201%200-1.387z%27%2F%3E%3C%2Fsvg%3E'); // TODO: background size
				padding-right: rh(1);
			}

			.b-theme_rle & {
				content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-rle}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E'); // TODO: use background

				@include media(sm) {
					content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%278%27%20height%3D%2714%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20fill%3D%27#{$color-arrows-rle}%27%20d%3D%27M.286%206.287L6.31.268a.98.98%200%200%201%201.387%201.386L2.368%206.98l5.329%205.327a.98.98%200%200%201-1.387%201.387L.286%207.674a.98.98%200%200%201%200-1.387z%27%2F%3E%3C%2Fsvg%3E'); // TODO: background size
					padding-right: rh(1);
				}
			}

			.b-theme_hmv & {
				content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-hmv}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M.429%208.997L9.465.384a1.523%201.523%200%200%201%202.081%200%201.357%201.357%200%200%201%200%201.984L3.552%209.989l7.994%207.621a1.358%201.358%200%200%201%200%201.984%201.523%201.523%200%200%201-2.081%200L.429%2010.98a1.366%201.366%200%200%201-.43-.991c0-.359.144-.718.43-.992z%27%2F%3E%3C%2Fsvg%3E');

				@include media(sm) {
					content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%278%27%20height%3D%2714%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20fill%3D%27#{$color-arrows-hmv}%27%20d%3D%27M.286%206.287L6.31.268a.98.98%200%200%201%201.387%201.386L2.368%206.98l5.329%205.327a.98.98%200%200%201-1.387%201.387L.286%207.674a.98.98%200%200%201%200-1.387z%27%2F%3E%3C%2Fsvg%3E');
				}
			}
		}
	}

	&.slick-next {
		right: 0;

		@include media(sm) {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			right: -15px;
		}

		.m-order_history & {
			@include media(sm) {
				right: 0;
			}
		}

		&::before {
			content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E'); // TODO: use background

			@include media(sm) {
				content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%278%27%20height%3D%2714%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20fill%3D%27#{$color-arrows}%27%20d%3D%27M7.712%207.689l-6.039%206.02a.987.987%200%200%201-1.391%200%20.979.979%200%200%201%200-1.387l5.344-5.326L.282%201.669a.978.978%200%200%201%200-1.386.986.986%200%200%201%201.391%200l6.039%206.02a.974.974%200%200%201%200%201.386z%27%2F%3E%3C%2Fsvg%3E'); // TODO: background size
				padding-left: rh(1);
			}

			.b-theme_rle & {
				content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-rle}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E'); // TODO: use background

				@include media(sm) {
					content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%278%27%20height%3D%2714%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20fill%3D%27#{$color-arrows-rle}%27%20d%3D%27M7.712%207.689l-6.039%206.02a.987.987%200%200%201-1.391%200%20.979.979%200%200%201%200-1.387l5.344-5.326L.282%201.669a.978.978%200%200%201%200-1.386.986.986%200%200%201%201.391%200l6.039%206.02a.974.974%200%200%201%200%201.386z%27%2F%3E%3C%2Fsvg%3E'); // TODO: background size
					padding-right: rh(1);
				}
			}

			.b-theme_hmv & {
				content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20fill%3D%27#{$color-arrows-hmv}%27%20width%3D%2712px%27%20height%3D%2721px%27%3E%3Cpath%20d%3D%27M11.568%2011.001l-9.035%208.614a1.525%201.525%200%200%201-2.081%200%201.357%201.357%200%200%201%200-1.984l7.994-7.621L.452%202.388a1.357%201.357%200%200%201%200-1.983%201.523%201.523%200%200%201%202.081%200l9.035%208.613c.287.274.431.632.431.992%200%20.358-.144.718-.431.991z%27%2F%3E%3C%2Fsvg%3E');

				@include media(sm) {
					content: url('data:image/svg+xml;charset=utf8,\%3Csvg%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27%20width%3D%278%27%20height%3D%2714%27%3E%3Cpath%20fill-rule%3D%27evenodd%27%20fill%3D%27#{$color-arrows-hmv}%27%20d%3D%27M7.712%207.689l-6.039%206.02a.987.987%200%200%201-1.391%200%20.979.979%200%200%201%200-1.387l5.344-5.326L.282%201.669a.978.978%200%200%201%200-1.386.986.986%200%200%201%201.391%200l6.039%206.02a.974.974%200%200%201%200%201.386z%27%2F%3E%3C%2Fsvg%3E');
				}
			}
		}
	}
}
