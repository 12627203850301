$popup-corner-shadow: -2px 2px 2px 0 hsla($color-brand-raw, 0.1);

.b-locator_popup {
	color: $color-text;
	position: relative;
	text-align: left;

	&.js-locator_popup_show {
		display: block;
	}

	&-inner {
		background-color: $color-white;
		border-radius: $global-radius;
		box-shadow: $depth-2;
		box-sizing: border-box;
		font-size: 14px;
		left: 0;
		padding: rh(6);
		position: absolute;
		top: 16px;
		width: 220px;
		z-index: z(popup-menu);

		@include media(md-down) {
			width: 180px;
		}

		p {
			line-height: 24px;
		}

		&::after {
			background: $color-white;
			box-shadow: $popup-corner-shadow;
			content: '';
			height: 15px;
			left: 20px;
			position: absolute;
			top: 0;
			transform: translate(-50%, -50%) rotate(135deg);
			width: 15px;
		}

		.b-locator_store-hours.m-store_locator & {
			@include media(lg-up) {
				left: auto;
				right: auto;
				transform: translate(0);

				&::after {
					left: 35px;
					right: auto;
				}
			}
		}
	}

	&-inner-top {
		top: -270px;

		@include media(md-down) {
			top: -290px;
		}

		&::after {
			bottom: -14px;
			top: auto;
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	&-title {
		font-weight: bold;
		margin-bottom: rh(2);
		text-transform: uppercase;
	}

	&-close {
		background: transparent;
		border: none;
		color: $color-brand76;
		cursor: pointer;
		height: 28px;
		padding: rh(2);
		position: absolute;
		right: 0;
		top: 0;
		width: 28px;
	}
}
