.b-dialog_alert {
	animation: fade-in ease 0.5s;
	background: lighten($color-yellow, 20%);
	border-radius: 0 0 $global-radius $global-radius;
	box-shadow: $depth-2;
	color: lighten($color-text, 20%);
	left: 50%;
	padding: rh(2);
	position: absolute;
	text-align: center;
	top: 3px;
	transform: translateX(-50%);
	width: 70%;
}
