.b-leading_lines {
	align-items: flex-end;
	display: flex;
	justify-content: space-between;
	margin-bottom: rh(2);

	&-label {
		padding-right: rh(3);
	}

	&-label_regular {
		font-weight: 400;
		text-transform: none;
	}

	&-value {
		&.m-items {
			color: $color-grey65;
			text-transform: none;
		}

		&.m-leading_minus {
			&::before {
				content: '-';
			}
		}
	}

	&.m-revert {
		.b-leading_lines-value {
			order: -1;
		}

		.b-leading_lines-label {
			padding-right: rh(0);
		}
	}

	&.m-bold {
		font-weight: 900;
		text-transform: uppercase;

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&.m-total {
		font-size: 18px;
	}

	&.m-with_divider {
		border-top: 1px solid $color-divider;
		margin-top: rh(6);
		padding-top: rh(6);
	}
}
