.b-footer_help {
	&-paragraph {
		margin-bottom: rh(3);
	}

	&-link {
		color: $color-white;
		text-decoration: underline;

		&:focus,
		&:hover {
			text-decoration: none;
		}
	}

	&-solid {
		@include solid-text(solid);
	}

	&-new_line {
		&.m-lg {
			@include media(lg-up) {
				@include solid-text(new_line);
			}
		}

		&.m-md {
			@include media(md) {
				@include solid-text(new_line);
			}
		}

		&.m-sm {
			@include media(sm) {
				@include solid-text(new_line);
			}
		}
	}
}
