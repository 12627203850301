@mixin t-table() {
	border-collapse: collapse;
	border-radius: $global-radius;
	box-shadow: $depth-1;
	margin: rh(10 0);
	width: 100%;

	caption {
		@include t-title-5;

		margin-bottom: rh(2);
		text-align: left;
	}

	td,
	th {
		border-bottom: 1px solid $color-brand88;
		padding: rh(5 8 4);
	}

	thead,
	tfoot {
		td,
		th {
			background-color: $color-brand97;
			border-bottom: 1px solid $color-brand88;
			padding: rh(5 8 4);
			text-align: left;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
	}
}
