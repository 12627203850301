.b-tile_image_container {
	@include g-product-image;

	&-link {
		display: block;
	}

	img {
		@include g-product-image(_img);
	}
}
