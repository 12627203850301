.b-gift_modal {
	max-width: 416px;

	&-wrapper {
		z-index: 13;
	}

	&-header {
		padding: rh(7 3 0);
	}

	&-title {
		margin-bottom: rh(5);
		text-align: left;
	}

	&-subtitle {
		color: $color-black;
		font-family: $font-sans;
		font-size: 16px;
		line-height: 22px;
		text-align: left;
		text-transform: none;
	}

	&-recaptcha {
		width: 100%;
	}

	&-info {
		color: $color-grey44;
		font-size: 14px;
		margin-top: rh(7);
		text-align: center;
	}

	&-image {
		margin: rh(6) auto 0;
		max-width: 200px;
		text-align: center;
	}

	&-result {
		border-top: 1px solid $color-grey91;
		display: flex;
		justify-content: space-between;
		margin-top: rh(10);
		padding: rh(6 0 7);
	}

	&-row {
		display: flex;
		justify-content: space-between;
	}

	&-balance {
		font-weight: bold;
	}

	&-help {
		text-align: center;
	}

	&-form {
		position: relative;
	}
}
