/*md

# Radio button example

```html_example
<div class="form-group">
	<input id="id-1" name="group" type="radio" class="custom-control-input">
	<label for="id-1" class="custom-control-label">
		Radio 1
	</label>
</div>
<div class="form-group">
	<input id="id-2" name="group" type="radio" class="custom-control-input">
	<label for="id-2" class="custom-control-label">
		Radio 2
	</label>
</div>
```
*/

.b-radio {
	@include g-radio;
}

// stylelint-disable selector-no-qualifying-type
.b-radio,
.form-group {
	input[type='radio'] {
		@include g-radio(_input);

		&:checked {
			@include g-radio(_input-checked);
		}

		&:hover {
			@include g-radio(_input-hover);
		}

		&:checked:hover {
			@include g-radio(_input-checked-hover);
		}

		&[disabled] {
			@include g-radio(_input-disabled);
		}
	}

	input[type='radio'] + label {
		@include g-radio(_label);
	}
}
// stylelint-enable
