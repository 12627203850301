.b-minicart_summary {
	margin-top: rh(8);

	&-item {
		margin-bottom: rh(4);

		.b-leading_lines-value {
			font-weight: 900;
		}
	}

	.b-theme_rle & .b-leading_lines-value {
		font-weight: 500;
	}
}
