.l-content {
	&:not(.l-content_pd) {
		@include site-width;

		display: block;
		position: relative;

		@media print {
			padding: 0 rh(4);
		}

		@include media(md) {
			margin-top: 17%;
		}

		@include media(sm) {
			margin-top: 22%;
		}

		.b-theme_hmv & {
			padding-top: 15px;
		}
	}
}

.b-checkout_header {
	+.l-content {
		margin-top: 0;
	}
}
