.b-footer_social {
	background-color: $color-brand;

	.b-theme_hmv & {
		background-color: var(--color-neutral-0);
	}

	&-section {
		@include site-width;
	}

	&-wrapper {
		display: flex;
		justify-content: center;
		padding: rh(12 0 7);

		@include media(sm) {
			justify-content: space-evenly;
		}
	}

	&-item {
		display: flex;
	}

	&-link {
		align-items: center;
		background-color: $color-white;
		border: 2px solid $color-white;
		border-radius: 50%;
		box-shadow: $depth-1;
		color: $color-brand;
		display: flex;
		fill: currentcolor;
		flex-direction: column;
		height: 40px;
		justify-content: center;
		width: 40px;

		.b-theme_hmv & {
			color: var(--color-neutral-0);
		}

		@include media(md-up) {
			margin: rh(0 6);
		}

		&:hover {
			background-color: transparent;
			border: 2px solid $color-white;
			color: $color-white;
		}
	}
}

.b-theme_roomsandspaces .b-footer_social {
	&-link {
		box-shadow: none;
	}
}
