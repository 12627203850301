.b-header_store {
	&-link {
		background: 0;
		border: 0;
		cursor: pointer;
		font-size: 14px;
		padding: 0 40px;
		position: relative;
		text-align: left;

		@include media(md-down) {
			margin-top: 4px;
			padding: 0 10px;
		}

		.b-theme_rle & {
			color: $color-dark_grey;
			font-family: $font-sans;
			font-size: 0.75rem;
		}
	}

	&-link-inner {
		@include media(md-down) {
			display: none;
		}
	}

	&-link-image {
		@include media(lg-up) {
			left: 0;
			position: absolute;
			top: 50%;
			transform: translate(50%, -50%);
		}

		path {
			fill: $color-brand;
		}
	}

	&-selected {
		font-weight: 700;

		@include media(md-down) {
			display: none;
		}

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-time {
		color: $color-brand;
		font-size: 12px;

		@include media(md-down) {
			display: none;
		}
	}

	&-selected-icon {
		@include media(md-down) {
			background: $color-red;
			border-radius: 5px;
			height: 7px;
			position: absolute;
			right: 11px;
			top: 0;
			width: 7px;
		}
	}
}

.new-layout {
	.b-header_store {
		@include media(lg-up) {
			width: 175px;

			&-selected {
				position: absolute;
			}
		}
	}
}
