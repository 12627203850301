.b-form {
	font-size: 14px;

	&-header {
		margin: rh(0 0 8);
	}

	&-header_title {
		font-size: 18px;
		font-weight: bold;
		margin: rh(4 0);

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-header_text {
		font-size: 16px;
	}

	&-small_print {
		display: block;
		font-size: 12px;
		margin: rh(1 0);
		text-align: center;
	}

	&_required {
		@include hide(visually);
	}

	&.m-toggled_area {
		.form-group.m-area-disabled {
			display: none;
		}

		.form-group.m-area-enabled {
			display: block;
		}
	}

	&_preferences {
		margin-top: 30px;
	}
}
