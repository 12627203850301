/* stylelint-disable */
.b-minicart_panel,
.b-pdp-instore_panel {
	@include g-dialog-backdrop;

	&.is-active {
		overflow: hidden;
	}

	&-panel,
	.instore_inventory-dialog-panel {
		background: $color-white;
		border-radius: 0;
		bottom: 0;
		display: block;
		margin: 0;
		max-width: 90%;
		position: fixed;
		right: 0;
		top: 0;
		transform: translateX(100%);
		transition: $motion-ease-panels;
		transition-property: transform;
		width: 480px;
		z-index: z(minicart);

		@include media(sm) {
			width: 400px;
		}

		&.is-open {
			transform: translateX(0);
		}

		&::before {
			background-attachment: fixed;
			background-image: linear-gradient($color-orange 0%, $color-orange 25%, $color-green 25%, $color-green 50%, $color-blue 50%, $color-blue 75%, $color-red 75%, $color-red 100%);
			background-size: 3px 253px;
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			top: 0;
			width: 3px;

			.b-theme_babies & {
				background-image: linear-gradient($color-pink 0%, $color-pink 25%, $color-blue51 25%, $color-blue51 50%, $color-yellow 50%, $color-yellow 75%, $color-purple 75%, $color-purple 100%);
			}

			.b-theme_hmv & {
				background-color: var(--color-brand);
				background-image: none;
				width: 9px;
			}

			.b-theme_rle & {
				background-image: linear-gradient($color-brand 0%, $color-brand 50%, $color-sand 50%, $color-sand 100%);
			}

			.b-theme_roomsandspaces & {
				background-image: none;
				background-color: $color-brand;
			}
		}
	}

	&-content,
	.instore_inventory-dialog-content {
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
	}

	&-empty {
		background-color: $color-white;
		display: none;
		height: 100%;
		left: 3px;
		position: absolute;
		top: 0;
		width: 100%;

		&.m-open {
			display: block;
		}
	}
}

.b-pdp-instore_panel {
	font: 16px/1.375 $font-sans;
	text-align: left;
}

.store-locator-container {
	.row {
		background-color: $color-white;
	}
}
