.b-session_timeout {
	&-body {
		line-height: 24px;
		text-align: center;
	}

	&-first_sentence {
		margin-bottom: rh(6);
	}

	&-countdown {
		display: block;
		font-size: 24px;
		margin: auto;
	}
}
