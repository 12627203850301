.b-form_caption {
	color: $color-grey65;
	display: block;
	margin: rh(0 0 2);

	label {
		word-break: break-word;
	}

	&-link {
		color: $color-grey65;
	}

	&.m-double {
		display: flex;
		justify-content: space-between;

		@include media(sm) {
			display: block;
		}

		.b-form_caption-item {
			@include media(sm) {
				margin-bottom: rh(1);
			}

			&.m-caption {
				@include media(md-up) {
					flex-basis: 75%;
					text-align: right;
				}
			}
		}
	}
}
