.b-promo_slot {
	> a,
	> picture,
	> div {
		margin-bottom: rh(7);

		@include media(sm) {
			margin-bottom: rh(3);
		}
	}

	img {
		width: 100%;
	}

	&.m-cat_landing_slotbanner {
		@include media(sm) {
			display: none;
		}
	}

	&.m-cat_landing_slotbanner_3 {
		@include media(md-down) {
			display: none;
		}
	}
}
