/*md

# Inputs and textarea

Basic text input component that allow user to input text information to be used by the form. This component cover single-line and multi-line text input.

```html
<form class="form-group">
	<input class="b-text-input" type="text" placeholder="Text input" />
	<input class="b-text-input" type="text" placeholder="Required" required />
</form>
```

## Text inputs types

This component designed to consistently style different types of inputs. Currently it cover `text, search, password, email, number` and `textarea`. Since we use generic component behind this binding the same styles is applied to the "combobox" or "selectbod" widget.

```html_example
<form class="form-group">
	<input class="b-text-input" type="text" placeholder="Text input" />
	</br>
	</br>
	<input class="b-text-input" type="password" placeholder="Password" />
	</br>
	</br>
	<input class="b-text-input" type="email" placeholder="Email" />
	</br>
	</br>
	<input class="b-text-input" type="number" placeholder="Number" />
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area"></textarea>
	</br>
	</br>
	<input class="b-button b-button_r b-button_black" type="submit" />
</form>
```

## Sizes

Only one size used for input widget.

```html_example
<form class="form-group">
	<input class="b-text-input" type="text" placeholder="Regular size" />
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area" placeholder="Regular size"></textarea>
	</br>
	</br>
</form>
```

## Text inputs states

This component have 2 states (stale, invalid) and 4 interaction states (hover, focus, disabled).

```html_example
<form class="form-group">
	<input class="b-text-input" type="text" placeholder="Default" />
	</br>
	</br>
	<input class="b-text-input m-hover" type="text" placeholder="Hover">
	</br>
	</br>
	<input class="b-text-input m-focus" type="text" placeholder="Focus">
	</br>
	</br>
	<input class="b-text-input m-invalid" type="text" placeholder="Invalid">
	</br>
	</br>
	<input class="b-text-input m-invalid m-focus" type="text" placeholder="Invalid focus">
	</br>
	</br>
	<input class="b-text-input" disabled type="text" placeholder="Disabled">
	</br>
	</br>

	<textarea class="b-text-input b-text-input_area" placeholder="Default"></textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area m-hover" placeholder="Hover"></textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area m-focus" placeholder="Focus"></textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area m-invalid" placeholder="Invalid"></textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area m-invalid m-focus" placeholder="Invalid focus"></textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area" disabled placeholder="Disabled"></textarea>
	</br>
	</br>

	<input class="b-text-input" type="text" placeholder="Default" value="User filled text">
	</br>
	</br>
	<input class="b-text-input m-hover" type="text" placeholder="Hover" value="User filled text">
	</br>
	</br>
	<input class="b-text-input m-focus" type="text" placeholder="Focus" value="User filled text">
	</br>
	</br>
	<input class="b-text-input m-invalid" type="text" placeholder="Invalid" value="User filled text">
	</br>
	</br>
	<input class="b-text-input m-invalid m-focus" type="text" placeholder="Invalid focus" value="User filled text">
	</br>
	</br>
	<input class="b-text-input" disabled type="text" placeholder="Disabled" value="User filled text">
	</br>
	</br>

	<textarea class="b-text-input b-text-input_area">User filled text</textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area m-hover">User filled text</textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area m-focus">User filled text</textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area m-invalid">User filled text</textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area m-invalid m-focus">User filled text</textarea>
	</br>
	</br>
	<textarea class="b-text-input b-text-input_area" disabled>User filled text</textarea>
	</br>
	</br>
</form>
```
 */
$input-padding: rh(3);
$font-size: 16px;
$height: 40px;
$root-line-height: 16px;

.form-group {
	font-size: 14px;
	margin: rh(4 0);

	@include media(sm) {
		margin: rh(3 0);
	}

	// stylelint-disable selector-no-qualifying-type, selector-class-pattern
	select {
		margin: rh(2 0);
	}

	input[type='text'],
	input[type='password'],
	input[type='email'],
	input[type='tel'],
	input[type='number'],
	input[type='date'],
	textarea {
		@include g-input;

		margin: rh(2 0);

		&:hover,
		&.m-hover {
			@include g-input(_hover);
		}

		&:focus,
		&.m-focus {
			@include g-input(_focus);
		}

		&.m-invalid,
		&.is-invalid {
			@include g-input(_invalid);
		}

		&[disabled],
		&.m-disabled {
			@include g-input(_disabled);
		}
	}

	textarea { // stylelint-disable-line
		height: auto; // Override height declaration for all inputs
		line-height: $root-line-height; // If we would use padding on inputs we don't need this override
		min-height: calc(#{$height} * 3);
		overflow: auto; // Removes default vertical scrollbar in IE6/7/8/9
		padding: calc((#{$height} - #{$root-line-height}) / 2) $input-padding;
		resize: vertical;
		vertical-align: top;
	}

	input[type='password'] { // stylelint-disable-line
		font-family: 'Verdana', sans-serif; // in case mobile typing is delay in hiding symbols with custom font
	}

	.invalid-feedback {
		color: $color-red;
		display: none;
		word-break: break-word;
	}

	.input-warning {
		color: $color-grey65;
		display: none;
		word-break: break-word;
	}

	label {
		user-select: none;
	}

	&.m-optional {
		label {
			&::after {
				color: $color-grey65;
				font-size: 12px;
				font-weight: 400;
				margin-left: rh(1);
				text-transform: none;

				[lang='fr'] & {
					content: '(facultatif)';
				}
			}
		}
	}

	&.required label {
		&:not([for='legal-consent'])::after {
			@include g-asterisk;
		}

		&.m-no-asterisk::after {
			background: none;

			@include media(sm) {
				display: none;
			}
		}

		&::before {
			@include hide(visually);

			content: attr(data-required-text);
		}
	}

	:-webkit-autofill {
		background-color: $color-white;
	}

	&.m-half_size {
		padding-right: calc(50% + #{rh(2)}); // same as in form line rh4 / 2

		@include media(sm) {
			padding-right: 0;
		}
	}

	&.m-third_size {
		padding-right: calc(66% + #{rh(3)}); // same as in form line rh4 / 2

		@include media(sm) {
			padding-right: 0;
		}
	}

	&.m-asided {
		align-items: baseline;
		display: flex;
		justify-content: space-between;
	}

	&.custom-checkboxes {
		border-bottom: 1px solid $color-brand;
		border-top: 1px solid $color-brand;
		padding: rh(2) 0;

		@include media(md-up) {
			display: flex;

			.custom-checkbox {
				width: 33.3%;
			}

			.custom-checkbox:not(:last-child) {
				padding-right: 15px;
			}
		}
	}

	&.bottom-border {
		border-bottom: 1px solid $color-brand;
		padding: rh(2) 0;
	}

	&-email {
		border-top: 1px solid $color-brand;
		padding-top: rh(4);
	}

	&-text {
		font-size: 13px; // used on account creation and subscription forms

		a {
			color: inherit;
			text-decoration: underline;

			&:hover {
				color: $color-link;
			}
		}
	}

	.b-mixed_label {
		display: inline-block;
		font-size: 16px; // used only on subscription form

		label { // stylelint-disable-line
			display: inline;
		}

		a {
			color: inherit;
			display: inline;
			text-decoration: underline;

			&:hover {
				color: $color-link;
			}
		}
	}

	.b-input_grouped {
		border-radius: $global-radius;
		box-shadow: $depth-1;
		display: flex;
		margin: rh(2 0);
		overflow: hidden;

		input[type='text'],
		button {
			box-shadow: none;
			margin: 0;
		}

		input {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}

		button {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			max-width: 100px;
			padding: 0;
		}
	}

	&.newsletter-container {
		flex-direction: column;

		.newsletter-title-wrapper {
			display: flex;
			flex-direction: column;
		}

		.newsletter-input-wrapper {
			display: flex;
			flex-direction: column;

			@include media(md-up) {
				flex-direction: row;
			}
		}
	}

	// stylelint-enable
}
