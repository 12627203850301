.b-price {
	display: flex;
	flex-wrap: wrap;
	font-weight: bold;
	white-space: nowrap;

	.b-theme_rle & {
		font-weight: 500;
	}

	&-sales {
		display: flex;
		flex-direction: column;
		order: 1;
	}

	&-new {
		color: $color-text;
		display: inline-block;
		margin-right: rh(2);
		order: 1;
	}

	&-old {
		color: darken($color-white, 35%);
		display: flex;
		flex-direction: column;
		margin-right: rh(7);
	}

	&-old &-value {
		text-decoration: line-through;
	}

	&-label {
		display: none;
		font-size: 14px;
		font-weight: normal;
		margin-bottom: rh(1);
	}

	&-range {
		white-space: initial;
	}

	&-tips {
		color: $color-grey44;
		display: block;
		font-size: 14px;
		font-weight: normal;
		margin-top: rh(2);
	}
}
