.b-header_search {
	$button-size: 40px;
	$border-width: 2px;
	$border-width-mobile: 1px;

	position: relative;
	z-index: z(search);

	@include media(md-up) {
		flex-grow: 1;
		padding-left: 20px;
	}

	@include media(sm) {
		background-color: $color-white;
		width: 100%;
	}

	button {
		background: $color-white;
		border: 0;
		border-radius: $global-radius;
		cursor: pointer;
	}

	&-input {
		border-radius: $global-radius;
		box-shadow: $depth-1;
		padding: rh(1);
		position: relative;
		z-index: z(components, header-search, combobox);

		@include media(sm) {
			padding-bottom: $border-width-mobile;
			padding-left: $border-width-mobile;
			padding-top: $border-width-mobile;
		}

		input {
			appearance: none;
			border: 0;
			border-radius: $global-radius;
			font: inherit;
			height: 32px;
			line-height: 32px;
			padding-left: 10px;
			position: relative;
			width: 100%;
			z-index: z(components, header-search, input);

			@include media(sm) {
				font-size: 15px;
			}

			&::-webkit-search-cancel-button,
			&::-webkit-search-decoration,
			&::-webkit-inner-spin-button {
				appearance: none;
			}

			&::-ms-clear {
				display: none;
			}

			&::placeholder {
				color: $color-grey65;
			}
		}
	}

	&-outline {
		border: 1px solid $color-brand94;
		border-radius: $global-radius;
		inset: 0;
		position: absolute;
		transition: $motion-ease;
		transition-property: border-width, border-color;
		z-index: z(components, header-search, outline);

		.b-theme_hmv & {
			border-color: var(--color-neutral-900);
		}

		.b-header_search-input:hover & {
			border-color: $color-brand;
		}

		.b-header_search-input:focus-within {
			border-color: $color-brand;
		}

		.b-header_search-input[aria-expanded='true'] & {
			border-color: $color-brand;
			border-width: $border-width;

			@include media(sm) {
				border-width: $border-width-mobile;
			}
		}

		.b-header_search-input input:focus + & {
			border-color: $color-brand;
		}
	}

	// stylelint-disable no-descending-specificity
	&-btn_search,
	&-btn_clear {
		bottom: $border-width + 2;
		position: absolute;
		right: $border-width + 2;
		top: $border-width + 2;
		transition: $motion-ease;
		transition-property: color;

		@include media(sm) {
			bottom: $border-width-mobile + 1;
			right: $border-width-mobile + 1;
			top: $border-width-mobile + 1;
		}
	}

	&-btn_search {
		color: $color-brand88;
		padding: 0 6px;
		z-index: z(components, header-search, buttons);

		.b-theme_hmv & {
			color: var(--color-neutral-700);
		}

		&:active,
		&:hover,
		.b-header_search-input:hover & {
			color: $color-brand;
		}

		.b-header_search-input:focus-within &,
		.b-header_search-input:hover:focus-within & {
			color: $color-brand;
		}

		svg {
			vertical-align: middle;
		}
	}
	// stylelint-enable
	&-btn_clear {
		animation: fade-in ease 0.4s;
		color: $color-brand76;
		display: none;
		margin-left: rh(3);
		padding: rh(0 3);
		right: -#{$button-size};
		z-index: z(components, header-search, buttons);

		@include media(sm) {
			right: $border-width-mobile;
		}

		@include media(md) {
			right: rh(0.5);
		}

		&:active,
		&:hover {
			color: $color-brand;
		}

		&.m-visible {
			display: block;
		}
	}

	&-suggestions {
		position: relative;
		text-align: left;
	}
}

.b-header_suggestions_flyout_pane {
	@include g-bottom-decoration; // stylelint-disable-line

	border-radius: 0 0 $global-radius $global-radius;
	inset: 0;
	opacity: 0;
	position: absolute;
	transition: $motion-ease;
	transition-property: height, opacity, visibility;
	visibility: hidden;
	z-index: z(search-flyout-pane);

	@include media(sm) {
		// Change flyout behaviour without touching JS logic

		min-height: 60px;
		opacity: 1 !important; // stylelint-disable-line
		padding-top: 0 !important; // stylelint-disable-line
		top: 0;
		transition-property: height;
		visibility: visible !important; // stylelint-disable-line
	}
}
