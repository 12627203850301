.b-minicart_product_summary {
	margin-top: rh(4);

	&-item {
		border-top: 1px solid $color-divider;
		margin-top: rh(4);
		padding-top: rh(4);
	}
}

.postal-code-cart,
.home-delivery-plp {
	.b-title {
		font-size: 14px;
		font-weight: lighter;
		text-transform: uppercase;
	}

	.header-postal-code_delivery {
		justify-content: flex-start;
		padding: 0;
	}
}
