.b-product_status {
	font-size: 14px;
	font-weight: bold;
	margin: rh(1 0);

	.b-theme_rle & {
		font-weight: 500;
	}

	&.m-thin {
		font-weight: 400;
	}

	&.m-comming_soon {
		color: $color-orange;
	}

	&.m-in_stock,
	&.m-instock {
		color: $color-green;
	}

	&.m-low_stock,
	&.m-lowstock {
		color: $color-orange;
	}

	&.m-contact_store,
	&.m-pre_order,
	&.m-preorder {
		color: $color-brand76;
	}

	&.m-pre_order,
	&.m-preorder {
		color: $color-brand;
	}

	&.m-backorder,
	&.m-back_order {
		color: $color-orange;
		white-space: normal;
	}

	&.m-pre_order {
		text-align: left;
		white-space: normal;
	}

	&.m-out_of_stock {
		color: $color-red;
	}

	&.m-status_soldout {
		color: $color-red;
	}

	&.m-unavailable {
		color: $color-brand64;
	}

	&.m-instock_date {
		font-weight: normal;
	}

	.b-theme_hmv & {
			color: var(--color-brand);
	}
}

.b-locator_card .b-product_status {
	text-transform: none;
}
