.b-availability_msg {
	background-color: rgba($color-red, 0.8);
	bottom: 0;
	color: $color-white;
	font-size: 12px;
	font-weight: 900;
	left: 0;
	padding: 6px;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	width: 100%;

	.b-theme_rle & {
		font-weight: 500;
	}

	&.m-instock {
		display: none;
	}

	&.m-outofstock {
		background-color: rgba($color-red, 0.8);
	}

	&.m-comming_soon {
		background-color: rgba($color-orange, 0.8);
	}

	&.m-instoreonly {
		background-color: rgba($color-green, 0.8);
	}

	&.m-pickuponly {
		background-color: rgba($color-green, 0.8);
	}

	&.m-backorder {
		background-color: rgba($color-orange, 0.8);
	}

	&.m-preorder {
		background-color: hsla($color-brand-raw, 0.8);
	}

	&.m-purchased {
		background-color: rgba($color-green, 0.8);
		text-transform: uppercase;
	}
}
