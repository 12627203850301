.l-header {
	padding: 30px 0;
	position: relative;

	@include media(md-down) {
		border-bottom: 1px solid $color-divider;
		padding: rh(4 0);
	}

	@media print {
		border-bottom: 4px solid $color-brand;
		height: auto;
		padding: rh(4 0);
	}

	.b-theme_rle & {
		@include media(lg-up) {
			padding: rh(4.5 0);
		}
	}

	&_wrapper {
		background: $color-white;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 10;

		@media print {
			position: absolute;
		}

		@include media(sm) {
			position: relative;
			height: auto;

			&.l-header-sticky {
				position: fixed;
			}
		}
	}

	&-inner {
		@include site-width;

		align-items: center;
		display: flex;
		justify-content: space-between;
	}

	&-actions {
		display: none;

		@include media(md-down) {
			display: flex;
			z-index: 6;
		}
	}

	&-logo {
		@include media(md-down) {
			max-width: 110px;
			min-width: 110px;
		}

		@include media(md) {
			margin-left: rh(5);
			margin-right: auto;
		}

		@include media(iphone-5) {
			margin-left: 5px;
			max-width: 80px;
			min-width: 80px;
		}
	}

	&-search {
		@include media(md-up) {
			&.m-opened {
				min-width: 300px;
			}
		}

		@include media(lg-up) {
			min-width: 564px;

			&.m-opened {
				min-width: 564px;
			}
		}

		@include media(md) {
			align-items: center;
			display: flex;
			margin-right: auto;
			width: 564px;
		}

		@include media(sm) {
			bottom: -66%;
			display: none;
			left: 0;
			margin-bottom: -7px;
			position: absolute;
			right: 0;

			&.m-active {
				align-items: center;
				display: flex;
				justify-content: space-between;
				padding: 20px 20px 24px;
			}
		}
	}

	&-search_icon {
		display: none;

		path {
			fill: $color-brand;
		}

		.l-header-sticky & {
			@include media(sm) {
				display: block;
				margin-top: 5px;
				padding: 0 8px;
			}
		}
	}

	&-status {
		align-items: center;
		display: flex;
		position: relative;
	}
}

.l-header-icons {
	display: flex;
	flex-direction: row;
}

.example-message {
	color: $color-red;
}

.location-postal-code {
	.form-group {
		align-items: flex-start;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;

		@include media(md-up) {
			justify-content: space-between;
		}
	}

	.input-wrapper {
		order: 1;
		width: 100%;
	}

	.b-form_actions {
		order: 3;

		@include media(md-up) {
			order: 2;
		}

		.b-button {
			margin: rh(2 0);
		}
	}

	.b-messages {
		order: 2;

		@include media(md-up) {
			order: 3;
		}
	}

	.input-wrapper,
	.b-form_actions {
		margin-bottom: 5px;
		margin-top: 0;
		width: 100%;

		@include media(md-up) {
			width: 48%;
		}
	}

	.main-message,
	.b-messages {
		@include media(md-down) {
			text-align: center;
		}
	}

	.french-wrapper {
		@include media(md-down) {
			.b-button {
				font-size: 14px;
			}
		}

		@include media(md-up) {
			.input-wrapper {
				width: 30%;
			}

			.b-form_actions {
				width: 65%;
			}
		}
	}
}

.js-modal-error_style {
	text-align: center;

	@include media(md-up) {
		text-align: left;

		.button-wrapper {
			text-align: center;
		}
	}

	.button-wrapper {
		margin-top: 2rem;
	}
}
