@include media(lg-up) {
	.mobile-container {
		display: none;
	}

	.b-desktop_bar {
		.hamburger-container {
			display: none;
			position: absolute;
			width: 360px;
			z-index: 7;
		}
	}

	.x-main_menu {
		background-color: $color-brand;
		font-size: 14px;
		line-height: 1.2;

		&.new-layout {
			background-color: $color-brand;
			color: $color-white;

			&-link {
				color: $color-brand;
			}
		}

		&-inner {
			align-items: stretch;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			list-style: none;
			margin: 0 auto;
			max-width: $max-content-width;
			overflow: hidden;
			height: 60px;
		}

		&-content {
			.l-header-sticky & {
				box-shadow: $depth-3;
			}
		}

		&-footer,
		&-top {
			display: none;
		}

		&-link {
			color: $color-text;
			display: block;
			font-weight: bold;
			text-decoration: none;

			&.m-navigation {
				color: $color-white;
				font-size: 15px;
				height: auto;
				line-height: 1.2;
				padding: rh(4);
				position: relative;
				text-align: center;
				text-transform: uppercase;

				&.bar-nav {
					background: none;
					border: 0;
					color: $color-brand;

					&.hamburger-desktop-button {
						cursor: pointer;
						font-family: inherit;

						span {
							align-items: center;
							display: flex;
						}

						svg {
							margin-right: 5px;
							transform: scale(0.85);
						}
					}

					&.m-support {
						display: none;
					}
				}
			}

			&:hover {
				text-decoration: none;
			}

			&::after {
				background-color: $color-brand;
				border-radius: 3px 3px 0 0;
				bottom: 0;
				content: '';
				display: block;
				height: 0;
				left: 0;
				opacity: 0;
				position: absolute;
				right: 0;
				transition: height ease 0.2s;
			}

			&:hover,
			&.m-toggled {
				&::after {
					height: 10px;
					opacity: 1;
					z-index: 10;
				}
			}
		}
	}
}
