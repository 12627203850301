.b-brands_menu {
	font-size: 14px;
	width: 100%;

	// Note this alphabet is used both in main menu and Brand landing menu
	&-alphabet {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: rh(0 -1 12);
		text-align: center;

		@include media(md-down) {
			justify-content: flex-start;
			text-align: left;
		}

		@include media(md) {
			margin: rh(0 -2.5 9);
		}

		@include media(sm) {
			margin: rh(0 0 9);
		}

		&.m-nav {
			@include media(lg-up) {
				margin-bottom: rh(9);
			}

			@include media(md) {
				margin: rh(6 0 0);
				padding: 0 calc(100% - 325px);
			}

			@include media(sm) {
				margin: rh(5 0 3);
				padding: 0 calc(100% - 265px);
			}
		}
	}

	&-button {
		border: 1px solid $color-link;
		border-radius: $global-radius;
		box-shadow: none;
		color: $color-link;
		display: inline-block;
		font-size: 14px;
		font-weight: bold;
		height: 30px;
		line-height: 27px;
		margin: rh(1);
		text-align: center;
		text-decoration: none;
		transition: $motion-ease;
		transition-property: box-shadow;
		white-space: nowrap;
		width: 30px;

		@include media(md-down) {
			height: 34px;
			line-height: 34px;
			width: 34px;
		}

		@include media(md) {
			margin: rh(0 2 5 2);

			.b-brands_menu-alphabet.m-nav & {
				margin: 0 11px 24px 17px;
			}
		}

		@include media(sm) {
			margin: 0 calc((100% - 170px) / 4) rh(6) 0;

			&:nth-child(5n) {
				margin-right: 0;
			}
		}

		&:visited {
			color: $color-link;
		}

		&:hover {
			background-color: $color-link;
			box-shadow: $depth-1;
			color: $color-white;
			text-decoration: none;
		}

		&:active,
		&:focus {
			background-color: $color-link;
			color: $color-white;
		}

		&.m-disabled {
			border-color: $color-grey77;
			color: $color-grey77;
			pointer-events: none;
		}

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-brands {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style: none;
		margin: rh(0 -3);
		text-align: center;

		@include media(md-down) {
			justify-content: flex-start;
			margin: rh(2 0);
			padding: rh(0) calc(100% - 326px);
		}

		@include media(sm) {
			justify-content: space-between;
			margin-top: rh(5);
			padding: 0 calc(100% - 265px);
		}
	}

	&-brand {
		border: 1px solid $color-brand94;
		border-radius: $global-radius;
		display: inline-block;
		height: 92px;
		margin: rh(2.5);
		overflow: hidden;
		position: relative;
		text-decoration: none;
		transition: border $motion-ease;
		width: 92px;

		@include media(md-down) {
			display: none;
			height: 68px;
			margin: rh(4.5); // in sake of consitancy
			width: 68px;

			&:nth-child(-n+9) {
				display: inline-block;
			}
		}

		@include media(sm) {
			margin: rh(0 5 5 0);

			&:nth-child(3n) {
				margin-right: 0;
			}
		}

		&::before {
			border-radius: $global-radius;
			box-shadow: $depth-1;
			content: '';
			inset: 0;
			opacity: 0;
			position: absolute;
			transition: opacity $motion-ease;
		}

		&:hover {
			border-color: $color-brand;

			&::before {
				opacity: 1;
			}
		}

		&:active {
			&::before { // stylelint-disable-line
				box-shadow: none;
			}
		}

		img {
			left: 0;
			max-width: 90px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@include media(md-down) {
				max-width: 68px;
			}
		}
	}

	&-footer {
		margin: rh(4 0 5);
		text-align: center;
	}

	&-title {
		color: $color-red;
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;

		@include media(md-down) {
			@include g-hamburger-button;

			background: $color-red;
			color: $color-white;
			font-size: 14px;
			text-align: center;

			.b-theme_babies & {
				background-color: $color-pink;
			}

			.b-theme_hmv & {
				background-color: var(--color-brand);
			}
		}
	}

	&-ln {
		color: $color-link;
		font-weight: bold;
		text-decoration: underline;
		text-transform: uppercase;
		user-select: none;

		&:hover {
			text-decoration: none;
		}

		.b-theme_rle & {
			font-weight: 500;
		}
	}
}
