.b-utility_help {
	&-link {
		color: rgba($color-white, 0.8);
		letter-spacing: 0.2px;
		text-decoration: none;
		text-transform: uppercase;

		&:focus,
		&:hover {
			color: $color-white;
			text-decoration: underline;
		}
	}
}
