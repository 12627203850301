$highlighter-color: $color-brand76;

.b-highlighter {
	border-radius: 1px;
	box-shadow: 0 0 0 3px $highlighter-color;
	box-sizing: content-box;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	transform: scale(1.2);
	transition-duration: 0.2s;
	transition-property: top, left, width, height, visibility, transform, opacity;
	transition-timing-function: ease-in-out;
	visibility: hidden;
	z-index: z(highlighter);

	&.m-visible {
		opacity: 1;
		transform: scale(1);
		visibility: visible;
	}

	&.m-hurry {
		transition-duration: 0.1s;
	}
}

*:focus { // stylelint-disable-line
	outline: 3px solid $highlighter-color !important; // stylelint-disable-line
}

.js-highlighter-inited *:focus { // stylelint-disable-line
	outline: none !important; // stylelint-disable-line
}
