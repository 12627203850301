.b-minicart {
	font-size: 16px;
	height: 100%;
	padding: rh(8 5 0);
	position: relative;
	text-align: left;

	&-title {
		color: $color-brand;
		font-family: $font-sans;
		font-size: 18px;
		font-weight: 900;
		text-transform: capitalize;

		.b-theme_rle & {
			font-weight: 500;
		}
	}

	&-close {
		background: transparent;
		border: none;
		color: $color-brand76;
		position: absolute;
		right: 20px;
		top: 35px;
		transition: color 0.3s ease;

		&:hover {
			color: $color-brand;
			cursor: pointer;
		}
	}

	&-btns {
		display: flex;
		justify-content: space-between;
		margin: rh(5 0);
		width: 100%;

		@include media(sm) {
			flex-wrap: wrap;
		}

		.b-button {
			width: 50%;

			@include media(sm) {
				width: 100%;
			}

			&:first-child {
				margin-right: rh(4);

				@include media(sm) {
					margin-bottom: rh(4);
					margin-right: 0;
				}
			}
		}
	}

	&-yield {
		height: 100%;
		width: 100%;
	}

	&-base_info {
		margin-top: rh(8);
	}

	&-footer {
		border-top: 1px solid $color-divider;
		margin: rh(8 0);
	}

	&-empty_content {
		align-items: center;
		display: flex;
		font-size: 16px;
		height: 100%;
		justify-content: center;
		padding: rh(10);
		text-align: center;
		width: 100%;

		.b-cart-title {
			font: 30px/1.2 $font-serif;

			.b-theme_rle & {
				letter-spacing: 0.03em;
			}
		}
	}

	&-error_availability_cart {
		color: $color-red;
		font-size: 14px;
		line-height: 1.29;
		margin-top: rh(2);
		text-align: left;
	}

	.b-availability_msg {
		&.m-outofstock {
			position: static;
			width: 70%;
		}
	}

	.h-country_selector {
		display: none;
	}
}
