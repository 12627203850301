.b-footer_copyright {
	background-color: $color-brand;
	color: $color-white;
	font: 16px/1.2 $font-sans;
	text-align: center;

	.b-theme_hmv & {
		background-color: var(--color-neutral-0);
	}

	&-section {
		@include site-width;
	}

	&-wrapper {
		padding: rh(0 0 8);
	}

	&-paragraph {
		margin-bottom: rh(3);
	}

	&-link {
		color: $color-white;
		text-decoration: underline;

		&:focus,
		&:hover {
			text-decoration: none;
		}
	}
}
