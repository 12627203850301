.b-error_page {
	font-family: $font-serif;
	margin: rh(25 0);
	text-align: center;

	@include media(md) {
		margin: rh(18 0);
	}

	@include media(sm) {
		margin: rh(10 0);
	}

	&-title {
		font-size: 64px;
		font-weight: 900;
		margin-bottom: rh(3);

		@include media(md-down) {
			font-size: 50px;
		}

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}
	}

	&-sub_title {
		font-size: 30px;
		font-weight: 900;
		margin-bottom: rh(7);
		text-transform: uppercase;

		@include media(md-down) {
			font-size: 24px;
			margin-bottom: rh(6);
		}

		.b-theme_rle & {
			letter-spacing: 0.03em;
		}
	}

	&-error_msg {
		font-family: $font-sans;
		font-size: 16px;
		margin-bottom: rh(10);

		@include media(md-down) {
			margin-bottom: rh(6);
		}
	}

	&-btn {
		margin: rh(0) auto;
		max-width: 300px;
	}

	&-image {
		display: inline-block;
		margin-top: rh(15);
	}
}
