@mixin g-product-image(
	$_: root,
	$_aspect: 100%
) {
	@if $_ == root {
		display: block;
		overflow: hidden;
		padding-bottom: $_aspect;
		position: relative;
		width: 100%;
	}

	@if $_ == _img {
		bottom: 0;
		display: block;
		height: 100%;
		left: 50%;
		object-fit: contain;
		position: absolute;
		top: 0;
		transform: translateX(-50%);
		width: 100%;
	}
}
