.b-utility_menu {
	color: $color-white;
	display: none;
	font-family: $font-sans;
	font-size: 12px;
	height: 48px;

	@include media(lg-up) {
		display: block;
	}

	&-container {
		@include site-width;

		align-items: center;
		display: flex;
		height: 100%;
		justify-content: space-between;
	}

	&-help {
		margin: rh(0 0 0 7);
	}

	&-navigation {
		margin: auto;
	}
}
